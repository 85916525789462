import * as React from 'react';
import $ from 'jquery';
import { debounce } from 'perfect-debounce';
import { useInView, InView } from "react-intersection-observer";
import classNames from 'classnames'
import { accentUtils, format, from, t } from '../../services/HelperService';
import { configFrom } from '../../services/ProductService';
import { ConfiguratorContext, ConfiguratorContextProvider } from './ConfigContextProvider';


import { getSizeRequirements, isOptionVisible, isReadOnly, saveOrderLineOptions} from './ProductConfiguratorShared';
import { OptionValue, SizeCtrl } from './VerticalConfiguratorCtrl';



export const TableConfiguratorCtrl = React.memo(props => {

    const table = React.useRef();            
    const lineRefs = React.useRef({});
    const [hidden, setHidden] = React.useState({});


    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {
                if (accentUtils.isNull(lineRefs.current)) return;

                const keys = Object.keys(lineRefs.current);

                for (var i = 0; i < keys.length; i++) {

                    const k = keys[i];

                    if (!lineRefs.current[k]) continue;

                    await lineRefs.current[k].save();
                }

                
            },
            cleanUp: () => {
                if (accentUtils.isNull(lineRefs.current)) return;

                const keys = Object.keys(lineRefs.current);

                for (var i = 0; i < keys.length; i++) {

                    const k = keys[i];

                    if (!lineRefs.current[k]) continue;

                    lineRefs.current[k].cleanUp();
                }

            },
            notifyQtyChanged: (lineID) => {

                if (accentUtils.isNull(lineRefs.current)) return;
                if (accentUtils.isNull(lineRefs.current[lineID])) return;

                lineRefs.current[lineID].notifyQtyChanged();
            },
            notifyLocationChanged: (lineID) => {

                if (accentUtils.isNull(lineRefs.current)) return;
                if (accentUtils.isNull(lineRefs.current[lineID])) return;

                lineRefs.current[lineID].notifyLocationChanged();

            }
        }),
        [lineRefs]
    );

    const productSize = props.config.GetSize();

    
    const size = getSizeRequirements(productSize);
    const hasSize = size.requireHeight || size.requireWidth || size.requireMultiHeight || size.requireMultiWidth;

    const groups = props.config.GetProductOptionGroups().toArray().filter(g => g.Active && !g.MultiProductOption);


    const onHiddenChanged = React.useCallback(e => {

        setHidden(h => ({ ...h, [e.orderLine.ID] : e.ids }));

    }, [setHidden]);

    React.useEffect(() => {
        console.log("TableConfiguratorCtrl Mount");
    }, []);

    let groupHeaders = [];
    let optionHeaders = [];        


    let allHidden = {};

    Object.keys(hidden).map(lineID => {

        const lineOptionIDs = hidden[lineID].split(",").reduce((p, v) => ({ ...p, [v]: true }), {});
        allHidden = { ...allHidden, ...lineOptionIDs };
    });

    groups.map(g => {

        const options = configFrom(props.config.GetProductOptions(g.ID)).where(o => o.Active && !allHidden[o.ID.toString().toUpperCase()]).toArray();
        let groupUsed = false;
        options.map(o => {

            optionHeaders.push(<th className="option-header" key={`p${o.ID}`} title={o.Description}><div>{o.Description}</div></th>);

            groupUsed = true;

        });

        if (groupUsed) {
            groupHeaders.push(<th colSpan={options.length} key={`p${g.ID}`} className="group-header">{g.Description}</th>);
        }

    });


    const keepLoaded = props.lines.length <= 30 || true;



    const lines =  props.lines.map(l => <TableLineRow
        defaultConfig={props.config}
        methods={r => lineRefs.current[l.ID] = r}
        key={`${l.viewChangeCount}_${l.ID}`}
        line={l}
        getPreColumns={props.getPreColumns}
        getPostColumns={props.getPostColumns}
        businessUnitID={props.businessUnitID}
        mpResellerKeys={props.mpResellerKeys}
        isQuote={props.isQuote}
        ignoreSizes={props.ignoreSizes}
        isLineReadOnly={props.isLineReadOnly}
        isReadOnly={props.isReadOnly}
        onChange={props.onChange}
        notifyDirty={props.notifyDirty}
        onLoaded={props.onLoaded}
        onSave={props.onSave}
        configFilter={props.configFilter}
        onHiddenChanged={onHiddenChanged}
        allHidden={allHidden}
        keepLoaded={keepLoaded}
        getMultiConfigManagerAsync={props.getMultiConfigManagerAsync}
        onProductSourceChanged={props.onProductSourceChanged}
        getAllowSourceScript={props.getAllowSourceScript }
        />);
    

    const className = classNames("config-table", props.className);

    return <div className={className} ref={table}>
        <table >
            <tbody>
                <tr>
                    {props.preGroupHeaders}
                    {hasSize && <th key="size" colSpan="2" >Size</th>}
                    {groupHeaders}
                    {props.postGroupHeaders}
                </tr>
                <tr>
                    {props.preOptionHeaders}
                    {(size.requireWidth || size.requireMultiWidth) && <th key="width" >{t("application_strings.views.multiLineEdit.width")}</th>}
                    {(size.requireHeight || size.requireMultiHeight) && <th key="drop" >{t("application_strings.views.multiLineEdit.drop")}</th>}
                    {optionHeaders}
                    {props.postOptionHeaders}
                </tr>
                {lines}
            </tbody>
        </table>
    </div>;

});



const TableLineRow = React.memo(props => {


    const getMultiConfigManagerAsync = props.getMultiConfigManagerAsync;
    
    const provider = React.useRef();


    const isMultiConfigLine = !accentUtils.isEmpty(props.line.MultiProductID);

    const [show, setShow] = React.useState(props.line.ID < 0);

    const [multiConfigManager, setMultiConfigManager] = React.useState(null);

    const { ref, inView } = useInView({
        threshold: 0,    
        delay: 100,
        skip: show
    });


    const updateShow = React.useCallback((e) => {

        if (!e.show && e.inView) {
            setShow(true);
            getMultiConfigManagerAsync(props.line, props).then(m => setMultiConfigManager(m));
        }

    }, [setShow, setMultiConfigManager, getMultiConfigManagerAsync, props]);

    const showDebounced = React.useRef(debounce(updateShow, 1000, { trailing: false }));


    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {

                if (provider.current) {
                    await provider.current.save();
                }
            },
            cleanUp: () => {

                if (provider.current) {
                    provider.current.cleanUp();
                }
            },
            notifyQtyChanged: () => {

                if (provider.current) {
                    provider.current.notifyQtyChanged();
                }                
            },
            notifyLocationChanged: () => {
                if (provider.current) {
                    provider.current.notifyLocationChanged();
                }                
            },
            
        }),
        [provider]
    );

    const onProductSourceChanged = React.useCallback(e => {

        if (props.onProductSourceChanged) {
            props.onProductSourceChanged(e, props.line);
        }
    }, [props]);

    const getAllowSourceScript = React.useCallback(e => {
        return props.getAllowSourceScript(props.line);
        
    }, [props]);

    

    const onChange = React.useCallback(e => {
        props.onChange();

        provider.current.save();

    }, [props, provider]);


    const onSaveOLOs = React.useCallback(e => {
        saveOrderLineOptions(props.line, e);
    }, [props]);

    const onInProgressChanged = e => {

        console.log(`TableLineRow onInProgressChanged - ${e}`);

        if (e)
            $(`#tr_${props.line.ID}`).addClass("change-in-progress");
        else
            $(`#tr_${props.line.ID}`).removeClass("change-in-progress");
    };

    React.useEffect(() => {
        console.log("TableLineRow Mount");
    }, []);


    React.useEffect(() => {

        if (!show) {
            showDebounced.current({ show, inView});
        }

    }, [show, inView, showDebounced]);


    React.useEffect(() => {

        if (show && isMultiConfigLine && accentUtils.isNull(multiConfigManager)) {

            getMultiConfigManagerAsync(props.line, props).then(m => setMultiConfigManager(m));

        }

    }, []);



    const lineIsReadOnly = props.isReadOnly || (props.isLineReadOnly && props.isLineReadOnly(props.line));

    const preColumns = props.getPreColumns ? props.getPreColumns(props.line, lineIsReadOnly) : null;
    const postColumns = props.getPostColumns ? props.getPostColumns(props.line, lineIsReadOnly) : null;



    if (!show || (isMultiConfigLine && accentUtils.isNull(multiConfigManager))) {        
        return <TableLineRowNotVisible
            ref={ref}
            defaultConfig={props.defaultConfig}
            line={props.line}
            getPreColumns={props.getPreColumns}
            getPostColumns={props.getPostColumns}
            allHidden={props.allHidden}
            table={props.table}
        />;
    } 


    return <tr
            key={`tr_${props.line.ID}`}
            id={`tr_${props.line.ID}`}
            ref={ref}
        >
            {preColumns}
            <ConfiguratorContextProvider
                methods={provider}
                productID={props.line.ProductID}
                orderLine={props.line}
                orderLineOptions={JSON.parse(props.line.OrderLineOptionsData)}
                businessUnitID={props.businessUnitID}
                mpResellerKeys={props.mpResellerKeys}
                isQuote={props.isQuote}
                ignoreSizes={props.ignoreSizes}
                isReadOnly={lineIsReadOnly}
                onValuedChanged={onChange}
                onValuedSize={onChange}
                notifyDirty={props.notifyDirty}
                onProductSourceChanged={props.onProductSourceChanged ?  onProductSourceChanged : undefined}
                allowProductSourceScript={getAllowSourceScript()}
                onLoaded={props.onLoaded}
                onSave={onSaveOLOs}
                filter={props.configFilter}
                onInProgressChanged={onInProgressChanged}
                multiConfigManager={multiConfigManager}
                loader={() => <CellLoader line={props.line} defaultConfig={props.defaultConfig} allHidden={ props.allHidden} /> }
            >
                <TableSizeCells defaultConfig={props.defaultConfig} />
                <TableOptionCells defaultConfig={props.defaultConfig} onHiddenChanged={props.onHiddenChanged} allHidden={props.allHidden} />
            </ConfiguratorContextProvider>
            {postColumns}
            </tr>;
        
});





const TableOptionCells = props => {


    const ctx = React.useContext(ConfiguratorContext);

    const hiddenOptions = React.useRef("");

    let cells = [];
    let hidden = [];


    configFrom(ctx.config.GetActiveGroupsForOrder())
        .where(g => !g.MultiProductOption)
        .select(g => {

            configFrom(ctx.config.GetActiveProductOptionsForOrder(g.ID))
                .select(o => {

                    const optionID = o.ID.toString().toUpperCase();

                    if (optionID === "E0E26DF6-5CC8-40EA-B5CF-4AE57707BC2C") {
                        const aa = 4;
                        const bb = aa;
                    }


                    const optionModel = ctx.config.GetOptionModel(o.ID);

                    const visible = isOptionVisible(optionModel, ctx.filter);

                    if (!props.allHidden[optionID]) {
                        cells.push(<TableOptionCell key={`key_${o.ID}`} option={o} />);
                    }

                    

                    if (!visible) {
                        hidden.push(optionID);
                    }


                }).toArray();

        }).toArray();


    hiddenOptions.current = hidden.join(",");


    React.useEffect(() => {
        props.onHiddenChanged({
            orderLine: ctx.orderLine,
            ids: hiddenOptions.current
        });
    }, [hiddenOptions.current]);

    return <React.Fragment>
        { cells}
    </React.Fragment>;
};


const TableSizeCells = props => {

    const productSize = props.defaultConfig.GetSize();

    if (productSize == null) {
        return null;
    }

    const size = getSizeRequirements(productSize);

    const width = size.requireWidth ? <td><SizeCtrl width small allowedValuesField="AllowedWidthList" productSize={productSize} requireMulti={size.requireMultiWidth || size.requireMultiHeight} /></td> : null;
    const height = size.requireHeight ? <td><SizeCtrl small allowedValuesField="AllowedHeightList" productSize={productSize} requireMulti={size.requireMultiWidth || size.requireMultiHeight} /></td> : null;



    return <React.Fragment>
        {width}
        { height}
    </React.Fragment>;
};



const TableOptionCell = props => {


    const ctx = React.useContext(ConfiguratorContext);
    const optionModel = ctx.config.GetOptionModel(props.option.ID);

    const readOnly = isReadOnly(optionModel);

    const itemID = `opt-${props.option?.ID?.toString()}`;

    return <td key={itemID} id={itemID} className={classNames("config-option-cell", { "readonly": readOnly })}>
        <OptionValue optionID={props.option?.ID?.toString()} displayType={optionModel.DisplayType} customDisplayType={props.option.CustomDisplayType} small />
    </td>;
};

const TableSizeCellsNotVisible = props => {

    const productSize = props.defaultConfig.GetSize();

    if (productSize == null) {
        return null;
    }

    const size = getSizeRequirements(productSize);

    const width = size.requireWidth ? <td>{format.formatLength(props.line.Width, props.line.ProductBaseUnit, props.line.ProductBaseUnitFormat)}</td> : null;
    const height = size.requireHeight ? <td>{format.formatLength(props.line.Drop, props.line.ProductBaseUnit, props.line.ProductBaseUnitFormat)}</td> : null;



    return <React.Fragment>
        {width}
        {height}
    </React.Fragment>;
};


const TableLineRowNotVisible = React.memo(React.forwardRef((props, ref) => {

   
    React.useImperativeHandle(
        props.methods,
        () => ({
            save: () => {
            }
        }),
        [props]
    );

   

    const preColumns = props.getPreColumns ? props.getPreColumns(props.line) : null;
    const postColumns = props.getPostColumns ? props.getPostColumns(props.line) : null;


    return <tr ref={ref} key={`tr_${props.line.ID}`} id={`tr_${props.line.ID}`}>
        {preColumns}
        <TableSizeCellsNotVisible defaultConfig={props.defaultConfig} line={props.line} />
        <TableOptionCellsNotVisible defaultConfig={props.defaultConfig} allHidden={props.allHidden} />
        {postColumns}
    </tr>;
}));


const CellLoader = props => {

    return <React.Fragment>
        <TableSizeCellsNotVisible defaultConfig={props.defaultConfig} line={props.line} />
        <TableOptionCellsNotVisible defaultConfig={props.defaultConfig} allHidden={props.allHidden} />
    </React.Fragment>;
};

const TableOptionCellsNotVisible = props => {

    const cells = [];

    const groups = configFrom(props.defaultConfig.GetProductOptionGroups())
        .where(g => g.Active && !g.MultiProductOption)
        .select(g => {

            configFrom(props.defaultConfig.GetProductOptions(g.ID))
            .where(o => o.Active && !props.allHidden[o.ID.toString().toUpperCase()])
                .select(o => {
                    const optionID = o.ID.toString().toUpperCase();
                    cells.push(<TableOptionCellNotVisible key={`key_${o.ID}`} option={o} />);
               
                }).toArray();

        }).toArray();

    return <React.Fragment>
        {cells}
    </React.Fragment>;
};




const TableOptionCellNotVisible = props => {
    const itemID = `opt-${props.option?.ID?.toString()}`;


    return <td id={ itemID} className={`config-option-cell`}>
        .........
    </td>;
};
