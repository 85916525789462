// AccentLengthImperialUnbound.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { debounce } from 'perfect-debounce';
import { accentUtils } from '../../services/HelperService';
import { AccentLabel } from '../AccentLabel';
import { AccentComboUnbound } from './AccentComboUnbound';
import { toLength, getFractions, toLong, generateFractions } from '../products/ProductConfiguratorShared';

/**
 * Helper function to split the defaultValue into yards, feet, inches, and fraction
 * based on the defaultUnit.
 * 
 * @param {number} value - The defaultValue in the specified unit.
 * @param {number} unit - The unit type (9144 for yards, 3048 for feet, 254 for inches).
 * @param {number} precision - The precision for fractions.
 * @returns {object} - An object containing yards, feet, inches, and fraction.
 */
const splitValue = (value, unit, precision) => {
    let yards = 0, feet = 0, inches = 0, fraction = 0;

    switch (unit) {
        case 9144: // Yards
            yards = Math.floor(value);
            let remainderYards = (value - yards) * 3; // 1 yard = 3 feet
            feet = Math.floor(remainderYards);
            let remainderFeet = (remainderYards - feet) * 12; // 1 foot = 12 inches
            inches = Math.floor(remainderFeet);
            fraction = Math.round((remainderFeet - inches) * precision) / precision;
            break;
        case 3048: // Feet
            feet = Math.floor(value);
            let remainderFeetOnly = (value - feet) * 12; // 1 foot = 12 inches
            inches = Math.floor(remainderFeetOnly);
            fraction = Math.round((remainderFeetOnly - inches) * precision) / precision;
            break;
        case 254: // Inches
        default:
            inches = Math.floor(value);
            fraction = Math.round((value - inches) * precision) / precision;
            break;
    }

    //console.log(`splitValue - value: ${value}`);
    //console.log(`splitValue - yards: ${yards}`);
    //console.log(`splitValue - feet: ${feet}`);
    //console.log(`splitValue - inches: ${inches}`);
    //console.log(`splitValue - fraction: ${fraction}`);

    return { yards, feet, inches, fraction };
};


export const AccentLengthImperialUnbound = ({
    defaultValue = 0,
    defaultUnit,
    onChange,
    onBlur,
    onFocus,
    min = 0,
    max = 1000, // Set a sensible default max if not provided
    debounceMilliseconds,
    className = '',
    label,
    required = false,
    invalid = false,
    disabled = false,
    placeHolder,
    step = 1,
    precision = 16, // 1/16
    dataTagName
}) => {
    // Split the defaultValue based on the defaultUnit
    const initialValues = splitValue(defaultValue, defaultUnit, precision);

    

    // Initialize state based on split values
    const [yards, setYards] = useState(initialValues.yards);
    const [feet, setFeet] = useState(initialValues.feet);
    const [inches, setInches] = useState(initialValues.inches);
    const [fraction, setFraction] = useState(initialValues.fraction);


    // Create a ref for the container
    const containerRef = useRef(null);

    // Handle container-level blur
    const handleContainerBlur = (e) => {
        // Check if the new focus is outside the container
        if (containerRef.current && !containerRef.current.contains(e.relatedTarget)) {
            if (onBlur) {
                const finalValue = calculateTotalValue();
                onBlur(finalValue);
            }
        }
    };

    // Handle container-level focus
    const handleContainerFocus = () => {
        if (onFocus) {
            const focusedValue = calculateTotalValue();
            onFocus(focusedValue);
        }
    };


    // Debounced notifyChange function
    const notifyChange = useRef(debounce((newValue) => {
        if (onChange) {
            onChange(newValue);
        }
    }, debounceMilliseconds || 500)).current;

    useEffect(() => {
        // Cleanup debounce on unmount
        return () => {
            notifyChange.cancel && notifyChange.cancel();
        };
    }, [notifyChange]);


    const calculateTotalValue = (overrides = {}) => {
        // Spread current state values first, then overrides
        const values = {
            yards,
            feet,
            inches,
            fraction,
            ...overrides
        };

        switch (defaultUnit) {
            case 9144: // Yards
                return toLength(values.yards + (values.feet / 3) + (values.inches / 36) + (values.fraction / 36), defaultUnit);
            case 3048: // Feet
                return toLength(values.feet + (values.inches / 12) + (values.fraction / 12), defaultUnit);
            case 254: // Inches
            default:
                return toLength(values.inches + values.fraction, defaultUnit);
        }
    };

   
    // Handlers for input changes
    const handleYardsChange = (e) => {
        let newYards = e.value;
        newYards = newYards < 0 ? 0 : newYards;
        setYards(newYards);


        notifyChange(calculateTotalValue({ yards: newYards ?? 0 }));


    };

    const handleFeetChange = (e) => {
        let newFeet = e.value;
        newFeet = newFeet < 0 ? 0 : newFeet;

        if (defaultUnit === 9144) { // Yards
            // For yards, no limit on feet since yards are not limited
            // Alternatively, you can set a max based on `max` if needed
        } else if (defaultUnit === 3048) { // Feet
            // Allow feet to be as large as needed, optionally limit based on max
            if (max) {
                const maxFeet = Math.floor(max / 12); // Example: max in feet based on inches
                newFeet = newFeet > maxFeet ? maxFeet : newFeet;
            }
        }

        setFeet(newFeet);
        notifyChange(calculateTotalValue({ feet: newFeet ?? 0 }));
    };

    const handleInchesChange = (e) => {
        let newInches = e.value;
        newInches = newInches < 0 ? 0 : newInches;

        // Only restrict to 11 inches if defaultUnit is not 254 (Inches)
        if (defaultUnit !== 254) {
            newInches = newInches > 11 ? 11 : newInches; // Prevent exceeding 11 inches
        }

        setInches(newInches);

        notifyChange(calculateTotalValue({ inches: newInches ?? 0 }));

    };

    const handleFractionChange = (value) => {
        

        setFraction(value);

        const total = calculateTotalValue({ fraction: value ?? 0 });
        notifyChange(total);
        onBlur(total);
    };



    // Generate fractions using provided helper function
    const fractions = generateFractions(precision || 16);

    const unitDisplayConfig = () => {
        switch (defaultUnit) {
            case 9144:
                return { showYards: true, showFeet: true, showInches: true };
            case 3048:
                return { showYards: false, showFeet: true, showInches: true };
            case 254:
                return { showYards: false, showFeet: false, showInches: true };
            default:
                return { showYards: false, showFeet: false, showInches: true };
        }
    };

    const { showYards, showFeet, showInches } = unitDisplayConfig();

    // Validation logic
    const isNotValid = required && (
        (showYards && accentUtils.isNull(yards)) ||
        (showFeet && accentUtils.isNull(feet)) ||
        (showInches && accentUtils.isNull(inches)) ||
        (showInches && accentUtils.isNull(fraction)) ||
        !!invalid
    );
    const inputClassName = isNotValid ? `accentNumberUnbound invalid ${className}` : `accentNumberUnbound ${className}`;

    // Styles for individual inputs
    const inputStyle = {};
    const fractionStyle = {};

    return (
        <AccentLabel text={label}>
            <div
                ref={containerRef}
                className="d-flex"
                style={{ width: '100%' }}
                data-tagname={dataTagName}
                onBlur={handleContainerBlur}
                onFocus={handleContainerFocus}
                tabIndex={-1} // Make the div focusable to capture focus events
            >
                {showYards && (
                    <div style={{ flex: '1 1 auto', marginRight: '8px', minWidth: '100px' }}>
                        {/* Yards Input */}
                        <NumericTextBox
                            value={yards}
                            onChange={handleYardsChange}
                            disabled={disabled}
                            onFocus={e => e.target.element.select()}
                            min={0}
                            step={1}
                            className={inputClassName}
                            style={inputStyle}
                            format="# yd"
                            placeholder="Yards"
                        />
                    </div>
                )}
                {showFeet && (
                    <div style={{ flex: '1 1 auto', marginRight: '8px', minWidth: '100px' }}>
                        {/* Feet Input */}
                        <NumericTextBox
                            value={feet}
                            onChange={handleFeetChange}
                            disabled={disabled}
                            onFocus={e => e.target.element.select() }
                            min={0}
                            max={defaultUnit === 3048 && max ? Math.floor(max / 12) : undefined}
                            step={1}
                            className={inputClassName}
                            style={inputStyle}
                            format="# ft"
                            placeholder="Feet"
                        />
                    </div>
                )}
                {showInches && (
                    <div style={{ flex: '1 1 auto', marginRight: '8px', minWidth: '100px' }}>
                        {/* Inches Input */}
                        <NumericTextBox
                            value={inches}
                            onChange={handleInchesChange}
                            disabled={disabled}
                            onFocus={e => e.target.element.select()}
                            min={0}
                            max={defaultUnit !== 254 ? 11 : undefined}
                            step={1}
                            className={inputClassName}
                            style={inputStyle}
                            format="# in"
                            placeholder="Inches"
                        />
                    </div>
                )}
                {showInches && (
                    <div style={{ flex: '1 1 auto', minWidth:'120px' }}>
                        {/* Fraction Input */}
                        <AccentComboUnbound
                            defaultValue={fraction}
                            onChange={handleFractionChange}
                            data={fractions}
                            displayField="Description"
                            valueField="ID"
                            disabled={disabled}
                            style={fractionStyle}
                            ariaLabel="Fractional Inches"
                        />
                    </div>
                )}
            </div>
        </AccentLabel>
    );
};

AccentLengthImperialUnbound.propTypes = {
    defaultUnit: PropTypes.oneOf([
        254,  // Inches
        3048, // Feet
        9144  // Yards
    ]).isRequired,
    defaultValue: PropTypes.number, // Changed from object to number
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    debounceMilliseconds: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    precision: PropTypes.number,
    dataTagName: PropTypes.string
};
