// AccentLengthUnbound.js
import React from 'react';
import PropTypes from 'prop-types';
import { AccentLengthMetricUnbound } from './AccentLengthMetricUnbound';
import { AccentLengthImperialUnbound } from './AccentLengthImperialUnbound';
import { denominators, fromDecimal, getMeasureValueUnitTypes, isMeasuredValue, toLength } from '../products/ProductConfiguratorShared';
import { from } from '../../services/HelperService';


export const AccentLengthUnbound = (props) => {
    const { defaultUnit, precision } = props;


    const defaultLength = isMeasuredValue(props.defaultValue) ? props.defaultValue : isNaN(Number(props.defaultValue)) ? undefined : toLength(Number(props.defaultValue), defaultUnit ?? 10);

    // Determine if the unit is metric using the provided helper function
    const unitTypes = getMeasureValueUnitTypes();
    const currentUnit = unitTypes.find(ut => ut.ID === defaultUnit);
    const isMetric = currentUnit ? currentUnit.IsMetric : true;

    const step = fromDecimal(precision);
    const value = fromDecimal(defaultLength?.Value ?? 0);


    const onChange = e => {
        props.onChange(e);
    };

    const onBlur = e => {

        if (props.onBlur)
            props.onBlur(e);
    };

    const onFocus = e => {

        if (props.onFocus)
            props.onFocus(e);
    };


    const precisionDemoninator = from(denominators).firstOrDefault(x => x.ID == step)?.Value;

    // Render the appropriate component based on the unit type
    return isMetric ? (
        <AccentLengthMetricUnbound {...props} step={step} defaultValue={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus } />
    ) : (
            <AccentLengthImperialUnbound {...props} defaultValue={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus} precision={precisionDemoninator } />
    );
};

AccentLengthUnbound.propTypes = {
    defaultUnit: PropTypes.oneOf([
        10,
        100,
        10000,
        254,
        3048,
        9144
    ]).isRequired,
    defaultValue: PropTypes.object, // Expected structure based on your helper functions
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    debounceMilliseconds: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    precision: PropTypes.number,
    dataTagName: PropTypes.string
};
