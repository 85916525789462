import React from 'react'
import { accentUtils, t, formHelper } from '../../services/HelperService'
import { AccentLengthUnbound } from '../unbound/AccentLengthUnbound';
import { fromDecimal, fromLength, toDecimal, toLength } from '../products/ProductConfiguratorShared';

export class AccentLength extends React.Component {

    constructor(props) {
        super(props);

        this.focused = false;


        this.refreshKey = 1;


        this.defaultUnit = this.props.model.options.defaultUnit ?? 10;
        this.defaultPrecision = toDecimal(this.props.model.options.defaultPrecision ?? 1);
        this.currentUserValue = this.props.model.getValue();

        this.defaultLength = toLength(this.currentUserValue, this.defaultUnit);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.isFocused = this.isFocused.bind(this);
        this.clearValue = this.clearValue.bind(this);
    }


    //kendo bug event not working
    handleBlur(e) {
        this.focused = false;
    }

    clearValue() {

        this.refreshKey++;
        this.props.model.setValue(0);
        this.forceUpdate();

    }

    handleChange(e) {

        const value = fromDecimal(e.Value);
            
        this.currentUserValue = value;

        this.props.model.setValue(value);

        if (!accentUtils.isNull(this.props.model.options.onChange)) {
            this.props.model.options.onChange();
        }


        this.props.model.modified();
    }

    handleFocus(e) {
        this.focused = true;
    }

    isFocused() {
        if (accentUtils.isNull(this.focused)) {
            this.focused = false;
        }
        return this.focused;
    }



    render() {
        var disabled = formHelper.isControlReadOnly(this.props.model);



        var modelValue = this.props.model.getValue();
        var min = this.props.model.options.min;
        var max = this.props.model.options.max;
        

        return (
            <AccentLengthUnbound                
                key={this.refreshKey}
                value={modelValue}
                disabled={disabled}
                min={min}
                max={max}
                label={this.props.model.displayLabel}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                defaultUnit={this.defaultUnit}
                precision={ this.defaultPrecision}
                defaultValue={this.defaultLength}
                 />            
        );


    }
}
