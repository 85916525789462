import * as React from 'react';
import { accentUtils } from '../../services/HelperService';
import { ConfiguratorContext } from './ConfigContextProvider';
import { isReadOnly, onSimpleOptionValueChanged, fromInt, useChanged, useValidation, getOptionModel, configEventControlType, configEvents, setOptionValueChanged } from './ProductConfiguratorShared';
import { AccentLengthUnbound } from '../unbound/AccentLengthUnbound';

export const ConfigLength = props => {

    const focusBug = React.useRef(false);

    const ctx = React.useContext(ConfiguratorContext)
    const optionModel = getOptionModel(ctx, props.optionID);


    const settings = optionModel.GetLengthSettings();

    const changed = useChanged(props.optionID, "ConfigLength", false);
    const validation = useValidation(props.optionID, "ConfigLength");

    const [value, setValue] = React.useState(optionModel.CurrentValue);


    const onChange = React.useCallback(e => {

        focusBug.current = false;

        console.log("ConfigLength: ", e.toString());

        optionModel.UpdateFrom$1(e);

        setOptionValueChanged(ctx, optionModel, changed.tag);

        setValue(e);

    }, [setValue, ctx, optionModel, changed.tag]);

    const onFocus = React.useCallback(e => {
        if (!focusBug.current) {
            focusBug.current = true;
            ctx.config?.Publish(configEvents.controlGotFocus, configEventControlType.decimal);
        }

    }, [ctx]);

    const notifyDirty = React.useCallback(e => {

        if (ctx.notifyDirty) {
            ctx.notifyDirty();
        }

    }, [ctx]);

    const readOnly = isReadOnly(optionModel);

    const min = fromInt(optionModel.MinValue);
    const max = fromInt(optionModel.MaxValue);


    console.log(`ConfigLength ${optionModel.Label}... ${value?.Value?.toString()} - ${changed.changeID}   ${optionModel.CurrentValue}`);

    return <div className="config-control config-decimal">
        <AccentLengthUnbound
            key={changed.changeID}
            defaultUnit={settings?.Unit ?? 10}
            precision={settings?.Precision}
            required={validation.required}
            defaultValue={optionModel.CurrentValue}
            onBlur={onChange}
            onChange={notifyDirty}
            onFocus={onFocus}
            disabled={readOnly}
            min={min}
            max={max === 0 ? undefined : max}
            debounceMilliseconds={50}
        />
    </div>;

};