import * as React from 'react';
import classNames from 'classnames';
import { accentUtils } from '../../services/HelperService';
import { configFrom, optionDisplayTypes } from '../../services/ProductService';
import { ConfigCheckbox } from './ConfigCheckbox';
import { ConfigDecimal } from './ConfigDecimal';
import { ConfigExtras } from './ConfigExtras';
import { ConfigNumeric } from './ConfigNumeric';
import { ConfigPanelPattern } from './ConfigPanelPattern';
import { ConfigPicklist } from './ConfigPicklist';
import { ConfigSingleLineFabric } from './ConfigSingleLineFabric';
import { ConfigSizeAllowed } from './ConfigSizeAllowed';
import { ConfigSizeSingle } from './ConfigSizeSingle';
import { ConfigText } from './ConfigText';
import { ConfigTwoLineFabric } from './ConfigTwoLineFabric';
import { ConfiguratorContext } from './ConfigContextProvider';

import './ProductConfigurator.css';
import { ActionBar, ConfigLabel, getSizeRequirements, isOptionVisible, isReadOnly, SizeIndicator, SizeLinkBtn, useChanged} from './ProductConfiguratorShared';
import { ConfigSizeAction } from './SelectSizeDlg';
import { ConfigLength } from './ConfigLength';


export const VerticalConfiguratorCtrl = React.memo(props => {


    const ctx = React.useContext(ConfiguratorContext)


    const groups = configFrom(ctx.config.GetActiveGroupsForOrder())
        .where(g => !g.MultiProductOption)
        .select(g => <Group className="options" key={`key_${g.ID}`} group={g} />).toArray();

    const extras = props.hideExtras ? null : configFrom(ctx.config.GetActiveGroupsForOrder())
        .where(g => g.MultiProductOption)
        .select(g => <Group className="extras" group={g} key={`key_${g.ID}`} />).toArray();


    React.useEffect(() => {
        ctx.config.ValidateOptions(true);
    }, []);


    return <div className="config-vertical">
        {!props.hideSize && <div className="config-group-container">
            <SizeGroup />
        </div>}
        <div className="config-group-container">
            {groups}
        </div>
        {!props.hideExtras && < div className="config-extras-container">
            {extras}
        </div>}
    </div>;

});


const SizeGroup = props => {

    const ctx = React.useContext(ConfiguratorContext)



    return <div className="config-group size">
        <div className="config-group-header">Size</div>
        <Size />
    </div>
};


const Group = props => {

    const ctx = React.useContext(ConfiguratorContext)
    const group = props.group;
    

    const options = group.MultiProductOption ? <ConfigExtras groupID={ group.ID.toString() } />
        : configFrom(ctx.config.GetActiveProductOptionsForOrder(group.ID))        
        .select(o => <Option key={ `key_${o.ID}`} option={o} />).toArray();


    return <div className={`config-group ${props.className}` }>
        <div className="config-group-header">{group.Description}</div>
        <div className="config-option-container">
            {options}
        </div>
    </div>

}


const Option = props => {

    const ctx = React.useContext(ConfiguratorContext)

    const option = props.option;
    const optionModel = ctx.config.GetOptionModel(option.ID);


    const changed = useChanged(option?.ID?.toString(), "OptionCtrl", false);

    const isVisible = isOptionVisible(optionModel, ctx.filter);

    const readOnly = isReadOnly(optionModel);

    const className = classNames("config-option", {
        "option-is-filtered": !isVisible,
        "readonly" : readOnly
    })

    return <div className={className} >
        <div className="config-option-header"><ConfigLabel option={ props.option} /></div>
        <div className="config-option-value-container">
            <OptionValue optionID={option?.ID?.toString()} displayType={optionModel.DisplayType} customDisplayType={option.CustomDisplayType} />            
            <ActionBar optionID={option?.ID?.toString()} />
        </div>
        
    </div>;
};


export const OptionValue = props => {


    let ctrl = null;
    
    switch (props.displayType) {
        case optionDisplayTypes.Picklist:
            ctrl = <ConfigPicklist optionID={props.optionID} small={ props.small } />;break;
        case optionDisplayTypes.Numeric: ctrl = ctrl = <ConfigNumeric optionID={props.optionID} />; break;
        case optionDisplayTypes.Decimal: ctrl = ctrl = <ConfigDecimal optionID={props.optionID} />; break;
        case optionDisplayTypes.Checkbox: ctrl = ctrl = <ConfigCheckbox optionID={props.optionID} />; break;
        case optionDisplayTypes.Length: ctrl = ctrl = <ConfigLength optionID={props.optionID} />; break;
        case "Custom":

            if (props.customDisplayType == "SoftFurnishings.Controls.FabricOption2LineCtrl, SoftFurnishings") {
                ctrl = ctrl = <ConfigTwoLineFabric optionID={props.optionID} small={props.small} />; break;                

            } else if (props.customDisplayType == "SoftFurnishings.Controls.FabricOptionCtrl, SoftFurnishings") {
                ctrl = ctrl = <ConfigSingleLineFabric optionID={props.optionID} />; break;
            } else if (props.customDisplayType == "InsyteProductModule.Controls.PanelPatternOptionCtrl, InsyteProductModule") {
                ctrl = ctrl = <ConfigPanelPattern optionID={props.optionID} />; break;
            }

            break;
        default:
            ctrl = <ConfigText optionID={props.optionID} />; break;
            break;

    }

    const className = classNames("config-value", { "small": props.small });

    return <div className={className} data-tagtname={ props.optionID }>
        {ctrl}
    </div>;

};


const Size = props => {

    const ctx = React.useContext(ConfiguratorContext)

    
    const productSize = ctx.config.Data.GetSize();

    if (productSize == null) {
        return null;
    }

    const size = getSizeRequirements(productSize);

    const width = size.requireWidth ? <SizeWidth productSize={productSize} requireMulti={size.requireMultiWidth || size.requireMultiHeight} /> : null;
    const height = size.requireHeight ? <SizeHight productSize={productSize} requireMulti={size.requireMultiWidth || size.requireMultiHeight} /> : null;

    
    return <div className="config-size-container">            
        { width }
        { height }
    </div>;

};


export const SizeCtrl = React.memo(props => {

    const fixedValues = React.useMemo(() => accentUtils.isEmpty(props.productSize[props.allowedValuesField]) ? [] : JSON.parse(props.productSize[props.allowedValuesField]), [props.productSize]);
    const isFixed = fixedValues.length !== 0;

    const className = classNames("config-size-value-container", {"small": props.small, "multi": props.requireMulti});

    return <div className={className} >
        {!isFixed && <ConfigSizeSingle width={ props.width} readOnly={props.requireMulti} isMulti={props.requireMulti} />}
        {isFixed && <ConfigSizeAllowed width={props.width} readOnly={props.requireMulti} sizes={fixedValues} small={props.small} />}
        <div className="config-size-value-actions">
            {props.requireMulti && <ConfigSizeAction width={ props.width} />}
        </div>
    </div>;
});

const SizeWidth = props => {

    return (<div className="config-size">
        <div className="config-size-header">
            <SizeIndicator width/>
            <div className="config-size-label">Width</div>
        </div>
        <SizeCtrl width allowedValuesField="AllowedWidthList" productSize={props.productSize} requireMulti={props.requireMulti}/>
    </div>);
};

const SizeHight = props => {

    return (<div className="config-size">
        <div className="config-size-header">
            <SizeIndicator />
            <div className="config-size-label">Height</div>
        </div>
        <SizeCtrl allowedValuesField="AllowedHeightList" productSize={props.productSize} requireMulti={props.requireMulti} />        
    </div>);
};