import * as React from 'react';
import { Row, Col} from 'reactstrap';
import { AccentDialog } from '../../dialogs/AccentDialog';
import AccentIcons from '../../icons/Icons';
import { accentUtils, showDialog, t } from '../../services/HelperService';
import { AccentButton } from '../AccentButton';
import { AccentNumber } from '../bound/AccentNumber';
import { AccentNumberUnbound } from '../unbound/AccentNumberUnbound';
import { ConfiguratorContext } from './ConfigContextProvider';
import { fromDecimal, fromInt, onSizeChanged, toDecimal, toInt } from './ProductConfiguratorShared';
import { AccentLengthUnbound } from '../unbound/AccentLengthUnbound';


export const ConfigSizeAction = props => {

    const key = `ConfigSizeAction_${props.width}`;
    const [_, setSizeID] = React.useState(0);

    const ctx = React.useContext(ConfiguratorContext);

    const baseUnit = ctx.config.Data.BaseUnit;
    const precision = ctx.config.Data.ProductPrecision;

    const productSize = ctx.config.Data.GetSize();
    const orderLine = ctx.config.OrderLine;

    const fireChange = React.useMemo(() => onSizeChanged(), [ctx.productID]);

    const viewModel = React.useMemo(
        () => new window.InsyteProduct.Models.MultiSizeViewModel(orderLine.Width, orderLine.Height, orderLine.WidthMeasurements, orderLine.HeightMeasurements, productSize),
        [orderLine.Width, orderLine.Height, orderLine.WidthMeasurements, orderLine.HeightMeasurements, productSize]
    );

    const onSizeChange = React.useCallback(e => {
        setSizeID(s => s + 1);
    }, [setSizeID]);



    const onClick = React.useCallback(e => {

        showDialog(<SelectSizeDlg

            unit={baseUnit}
            precision={precision }

            topWidth={ viewModel.Width1Value}
            middleWidth={viewModel.Width2Value}
            bottomWidth={viewModel.Width3Value}
            leftHeight={viewModel.Height1Value}
            middleHeight={viewModel.Height2Value}
            bottomHeight={viewModel.Height3Value}

            showTopWidth={viewModel.Width1Visible}
            showMiddleWidth={viewModel.Width2Visible}
            showBottomWidth={viewModel.Width3Visible}
            showLeftHeight={viewModel.Height1Visible}
            showMiddleHeight={viewModel.Height2Visible}
            showBottomHeight={viewModel.Height3Visible}

            
        />).then(res => {

            if (!res.canceled) {


                viewModel.Width1Value = res.topWidth;
                viewModel.Width2Value = res.middleWidth;
                viewModel.Width3Value = res.bottomWidth;
                viewModel.Height1Value = res.leftHeight;
                viewModel.Height2Value = res.middleHeight;
                viewModel.Height3Value = res.bottomHeight;



                orderLine.Width = viewModel.CalculatedWidth.RoundTo(precision);
                orderLine.Height = viewModel.CalculatedHeight.RoundTo(precision);
                orderLine.WidthMeasurements = viewModel.WidthMeasurements;
                orderLine.HeightMeasurements = viewModel.HeightMeasurements;

                fireChange(ctx);

            }

        });

    }, [viewModel, orderLine, fireChange, ctx, baseUnit, precision]);



   
    React.useEffect(() => {

        ctx.config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, onSizeChange, key);

        return () => {
            ctx.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, key);
        };

    }, [ctx, key]);


    return <AccentIcons.More onClick={onClick } />;
};


export const SelectSizeDlg = props => {

    const [topWidth, setTopWidth] = React.useState(props.topWidth);
    const [middleWidth, setMiddleWidth] = React.useState(props.middleWidth);
    const [bottomWidth, setBottomWidth] = React.useState(props.bottomWidth);

    const [leftHeight, setLeftHeight] = React.useState(props.leftHeight);
    const [middleHeight, setMiddleHeight] = React.useState(props.middleHeight);
    const [bottomHeight, setBottomHeight] = React.useState(props.bottomHeight);


    const onClose = React.useCallback(e => {

        props.onClose({
            topWidth, middleWidth, bottomWidth, leftHeight, middleHeight, bottomHeight
        });

    }, [props, topWidth, middleWidth, bottomWidth, leftHeight, middleHeight, bottomHeight]);



    const widthIsSingle = props.showTopWidth && !props.showMiddleWidth && !props.showBottomWidth; 
    const heightIsSingle = props.showLeftHeight && !props.showMiddleHeight && !props.showBottomHeight; 


    return <AccentDialog
        onClose={props.onClose}
        headerContent={"Size Details"}
        actionsContent={[<AccentButton key="2" tagName="sizeCloseBtn" onClick={onClose}>{t("application_strings.application.buttons.ok")}</AccentButton>]}
    >
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={3}></Col>
                    <Col md={3}>Width (mm)</Col>
                    <Col md={3}></Col>
                    <Col md={3}>Height (mm)</Col>
                </Row>
                <Row>
                    <Col md={3}>{props.showTopWidth && !widthIsSingle ? "Top" : ""}</Col>
                    <Col md={3}>{props.showTopWidth && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setTopWidth} min={0} defaultValue={props.topWidth} />}</Col>
                    <Col md={3}>{props.showLeftHeight && !heightIsSingle ? "Left" : ""}</Col>
                    <Col md={3}>{props.showLeftHeight && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setLeftHeight} min={0} defaultValue={props.leftHeight} />}</Col>
                </Row>
                <Row>
                    <Col md={3}>{props.showMiddleWidth ? "Middle" : ""}</Col>
                    <Col md={3}>{props.showMiddleWidth && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setMiddleWidth} min={0} defaultValue={props.middleWidth} />}</Col>
                    <Col md={3}>{props.showMiddleHeight ? "Middle" : ""}</Col>
                    <Col md={3}>{props.showMiddleHeight && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setMiddleHeight} min={0} defaultValue={props.middleHeight} />}</Col>
                </Row>
                <Row>
                    <Col md={3}>{props.showBottomWidth ? "Bottom" : ""}</Col>
                    <Col md={3}>{props.showBottomWidth && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setBottomWidth} min={0} defaultValue={props.bottomWidth} />}</Col>
                    <Col md={3}>{props.showBottomHeight ? "Right" : ""}</Col>
                    <Col md={3}>{props.showBottomHeight && <AccentLengthUnbound defaultUnit={props.unit} precision={props.precision} onChange={setBottomHeight} min={0} defaultValue={props.bottomHeight} />}</Col>
                </Row>

            </Col>

        </Row>
    </AccentDialog>;
};