import { queryNoTracking , update} from './DataService'
import { accentUtils } from './HelperService'




class AppSettingsService {

    saveCount = 0;
    settings = null;

    async Initialize() {


        await this.reloadSettings();


        return this.settings;
    }


    GetSketchSettings() {
        return this.settings.Sketch;
    }

    SetSketchSettings(e) {
        this.settings.Sketch = e;
        this.saveSetting();
    }


    GetDashboard() {
        return { ...this.settings.Dashboard };
    }

    SetDashboard(e) {
        this.settings.Dashboard = { ...e };
        this.saveSetting();
    }

    SetCalendar(e) {
        this.settings.Calendar = { ...this.settings.Calendar, ...e };
        return this.saveSetting();
    }

    GetCalendar() {
        return { ...this.settings.Calendar };
    }


    GetAppSettings() {
        return { ...this.settings.Application };
    }


    SetAppSettings(e) {
        this.settings.Application = { ...this.settings.Application, ...e };

        this.saveSetting();

    }



    GetActivityFilter() {
        return { ...this.settings.ActivityFilter };
    }


    SetActivityFilter(e) {
        this.settings.ActivityFilter = { ...this.settings.ActivityFilter, ...e };
        this.saveSetting();

    }


    async reloadSettings() {

        this.settings = await queryNoTracking("CurrentApplicationSettings").getFirstOrDefault();

        if (!accentUtils.isNull(this.settings.ActivityFilter)) {

            if (!accentUtils.isNull(this.settings.ActivityFilter.dateFilter)) {
                if (!accentUtils.isNull(this.settings.ActivityFilter.dateFilter.from)) {
                    this.settings.ActivityFilter.dateFilter.from = new Date(this.settings.ActivityFilter.dateFilter.from);
                }
                if (!accentUtils.isNull(this.settings.ActivityFilter.dateFilter.to)) {
                    this.settings.ActivityFilter.dateFilter.to = new Date(this.settings.ActivityFilter.dateFilter.to);
                }
            }
        }

    }


    ForceSave() {
        return update("SaveCurrentApplicationSettingsModel", { ...this.settings });
    }


    saveSetting() {

        this.saveCount++;


        return new Promise(p => {

            window.setTimeout(async () => {

                this.saveCount--;

                if (this.saveCount < 0 || this.saveCount > 250)
                    this.saveCount = 0;



                if (this.saveCount == 0) {
                    await update("SaveCurrentApplicationSettingsModel", { ...this.settings });
                }

                p();


            }, 3000);




        });



    }


}

export const Settings = new AppSettingsService();
