import * as React from 'react';
import { useState } from 'react';
import { accentUtils, from, showDialog, waitForCondition } from '../../services/HelperService';
import { getProduct, getProductConfig, getProducts } from '../../services/ProductService';
import { AccentSpinner } from '../AccentSpinner';
import { MultiConfigApplyOutstandingDlg } from './MultiConfigApplyOutstandingDlg';
import { applyMultiProductChanges, compareValues, fromInt, saveOrderLineOptions, toDecimal, toGuid, toInt, updateConfigWithSize, useConfigInProgress } from './ProductConfiguratorShared';

export const ConfiguratorContext = React.createContext();

export const MultiConfigApplyChangesTag = "multi-config-apply-changes";


export const reallocateProductIDsAndCreateLines = async (primaryLine, lines, memberProducts, onNewMultiLine, createMissing) => {
    // Create a Set for quick lookup of memberProductIDs


    const memberProductsByConfigID = from(memberProducts).toDictionary(x => x.ID);


    // First, identify lines with ProductIDs not in memberProductIDs and set their ProductID to null
    lines.forEach(line => {
        if (!memberProductsByConfigID.contains(line.MultiConfigID)) {
            line.MultiConfigID = null;
            line.ProductID = null;
        }

        lines.MultiGroupID = primaryLine.MultiGroupID;
        lines.MultiProductID = primaryLine.MultiProductID;

    });

    // Mapping to keep track of allocated ProductIDs
    const allocatedConfigIDs = new Set();

    // Allocate ProductIDs to lines, prioritizing lines without a ProductID
    lines.forEach(line => {
        if (accentUtils.isNull(line.MultiConfigID)) {
            const availableConfig = memberProducts.find(mp => !allocatedConfigIDs.has(mp.ID));
            if (!accentUtils.isNull(availableConfig)) {
                line.ProductID = availableConfig.MemberProductID;
                line.MultiConfigID = availableConfig.ID;
                allocatedConfigIDs.add(availableConfig.ID);
            }
        } else {
            allocatedConfigIDs.add(line.MultiConfigID); // Mark existing valid ProductIDs as allocated
        }
    });



    // Find unallocated ProductIDs and create new lines for them
    const unallocatedConfigs = memberProducts.filter(mp => !allocatedConfigIDs.has(mp.ID));
    let newLines = [];

    for (let i = 0; i < unallocatedConfigs.length; i++) {


        if (!onNewMultiLine) continue;


        const product = getProduct(unallocatedConfigs[i].MemberProductID, unallocatedConfigs[i].MemberVersionID);


        if (accentUtils.isNull(product)) continue;


        const newLine = await onNewMultiLine(primaryLine);
        newLine.ProductID = unallocatedConfigs[i].MemberProductID;
        newLine.ProductVersionID = product.VersionID;
        newLine.MultiConfigID = unallocatedConfigs[i].ID;
        newLine.MultiGroupID = primaryLine.MultiGroupID;
        newLine.MultiProductID = primaryLine.MultiProductID;
        newLine.SortIndex = primaryLine.SortIndex;
        newLine.Width = primaryLine.Width;
        newLine.Drop = primaryLine.Drop;
        newLine.WidthMeasurements = primaryLine.WidthMeasurements;
        newLine.DropMeasurements = primaryLine.DropMeasurements;
        newLine.SupplierID = product.SupplierKey;
        newLine.SupplierName = product.Supplier;

        if (!createMissing)
            newLine.__configExcluded = true;

        newLines.push(newLine); // Ensure new lines are also pushed to the newLines array
    }


    return from([...lines, ...newLines])
        .select(l => ({ line: l, sortIndex: memberProductsByConfigID.get(l.MultiConfigID).SortOrder }))
        .orderBy(x => x.sortIndex)
        .select(x => x.line)
        .toArray();


};


const loadConfigModel = async ({ orderLine, orderLineOptions, isReadOnly, isQuote, onSave, onChange, ignoreSizes, onNewOrderLineOption, businessUnitID, mpResellerKeys, companyID }) => {

    let companyClientRef = "";
    if (!accentUtils.isNull(businessUnitID)) {
        companyClientRef = `BU_${businessUnitID}`;
    }

    const companyInfo =
        [
            { CompanyID: mpResellerKeys, CompanyClientReference: companyClientRef }
        ];

    return await getProductConfig(
        orderLine,
        orderLineOptions,
        isReadOnly,
        isQuote,
        onSave, //save olos
        onChange,
        ignoreSizes,
        companyInfo,
        onNewOrderLineOption,
        businessUnitID,
        companyID
    )

};


export function getNewMultiConfigManager(props) {
    return new MultiConfigManager(props);
}


export function getConfigPropsFrom(props) {
    return (({
        isQuote,
        mpResellerKeys,
        businessUnitID,
        filter,
        notifyDirty,
        onProductSourceChanged,
        allowProductSourceScript,
        ignoreSizes,
        hideSize,
        hideExtras,
        companyID
    }) => ({
        isQuote,
        mpResellerKeys,
        businessUnitID,
        filter,
        notifyDirty,
        onProductSourceChanged,
        allowProductSourceScript,
        ignoreSizes,
        hideSize,
        hideExtras,
        companyID
    }))(props);
}

class MultiConfigManager {

    

    constructor(props) {

        this.configProps = getConfigPropsFrom(props);

        this.props = props;

        this.orderLines = props.orderLines;

        this.memberProductDefinitionsByConfigID = (props.product.MemberProducts ?? []).reduce((res, item) => ({ ...res, [item.ID]: item }), {});;
        this.linesByID = (props.orderLines ?? []).reduce((res, item) => ({ ...res, [Number(item.ID)]: item }), {});

        this.optionLinkCache = {};
        this.contextsByLineID = {};
        this.changesByLineID = {};
        this.registrationKeys = {};
        this.forceCreateMissing = props.forceCreateMissing;

        props.orderLines.map(line => {

            if (line.ID <= 0 || this.forceCreateMissing) {


                if (this.allowSizeLinking(line) || this.forceCreateMissing) {
                    line.MultiSizeWidthLinked = true;
                    line.MultiSizeHeightLinked = true;

                    line.Width = props.primaryLine.Width;
                    line.Drop = props.primaryLine.Drop;
                    line.WidthMeasurements = props.primaryLine.WidthMeasurements;
                    line.DropMeasurements = props.primaryLine.DropMeasurements;

                }

            }

            line.MultiProductVersionID = props.primaryLine.MultiProductVersionID;

        });


        //this.getProductLabelForLineID = this.getProductLabelForLineID.bind(this);
        //this.onNewOrderLineOption = this.onNewOrderLineOption.bind(this);
        //this.hasChanges = this.hasChanges.bind(this);
        //this.getConfig = this.getConfig.bind(this);
        //this.prepareSaveLineForMultiPoduct = this.prepareSaveLineForMultiPoduct.bind(this);
        //this.allowSizeLinking = this.allowSizeLinking.bind(this);
        //this.canReceiveSizeFrom = this.canReceiveSizeFrom.bind(this);
    }

    getLineFromID(lineID) {

        let line = this.linesByID[lineID];

        if (accentUtils.isNull(line)) {
            line = from(Object.values(this.linesByID)).firstOrDefault(l => l.ID === lineID);
            this.linesByID[lineID] = line;
        }

        return line;

    }


 
    setFilter(filter) {

        this.configProps = { ...this.configProps, filter: filter };

    }

    getProductLabelForLineID(lineID){

        const line = this.getLineFromID(lineID);

        const config = this.memberProductDefinitionsByConfigID[line.MultiConfigID];
        const label = accentUtils.isEmpty(config.Caption) ? getProduct(config?.MemberProductID)?.ProductName : config.Caption;
        const required = config.Required ? "*" : "";

        return `${label}${required}`;

    }

    onNewOrderLineOption(newOLO) {
        newOLO.LinkedToSet = true;
    }

    async waitWhileInProgress(){
        const lineIDs = Object.keys(this.contextsByLineID);

        for (var i = 0; i < lineIDs.length; i++) {
            const ctx = this.contextsByLineID[lineIDs[i]];
            await ctx?.waitWhileInProgress();

        }

        return false;
    }

    hasChanges(){
        return from(Object.keys(this.changesByLineID)).selectMany(lineID => this.changesByLineID[lineID]).any();
    }



    async getConfig(lineID, wait, forceLoad){

        if (wait) {

            const line = this.getLineFromID(lineID);

            if (accentUtils.isNull(line)) return null;

            if (line.__configExcluded && !forceLoad) return null;

            await waitForCondition(() => !accentUtils.isNull(this.contextsByLineID[lineID]), 200, 50);
        }

        return this.contextsByLineID[lineID];

    }

    getLineMultiConfigID(line) {


        if (accentUtils.isEmpty(this.props.getMultiConfig())) return null;

        const rtlOrderLine = line.ol ? line.ol : line;

        return rtlOrderLine.MultiConfigID.toString().toUpperCase();


    }



    allowSizeLinking(line){

        const configIDString = this.getLineMultiConfigID(line);

        return from(this.props.getMultiConfig()).any(m => m.LinkedSizes.toUpperCase().includes(configIDString));

    }

    canReceiveSizeFrom(fromLineID, toLineID){


        if (accentUtils.isEmpty(this.props.getMultiConfig())) return false;


        const fromLine = from(this.orderLines).firstOrDefault(l => Number(l.ID) === Number(fromLineID));
        const toLine = from(this.orderLines).firstOrDefault(l => Number(l.ID) === Number(toLineID));

        const toConfig = from(this.props.getMultiConfig()).firstOrDefault(m => compareValues(m.ID, toGuid(toLine.MultiConfigID)));

        if (accentUtils.isEmpty(toConfig?.LinkedSizes)) {
            return false;
        }

        return toConfig.LinkedSizes.toUpperCase().includes(fromLine.MultiConfigID.toUpperCase());



    };


    async applyingChanges(expectedLineID){

        //ensure config is loaded for expected line
        if (expectedLineID) {
            await this.getConfig(expectedLineID, true);
        }

        const lineIDs = Object.keys(this.changesByLineID);


        for (var i = 0; i < lineIDs.length; i++) {

            const lineID = Number(lineIDs[i]);
            const config = this.contextsByLineID[lineID]?.config;
            const changes = this.changesByLineID[lineID];

            if (accentUtils.isNull(config)) continue;


            await applyMultiProductChanges(config, changes, this.props.getMultiConfig(), MultiConfigApplyChangesTag);
        }

    };


    async applyingChangesWithUnloadedConfigs(){


        const hasOutstandingChanges = this.hasChanges();

        if (!hasOutstandingChanges) return;




        const applyActions = [];


        for (var i = 0; i < this.orderLines.length; i++) {

            const orderLine = this.orderLines[i];
            const lineID = Number(orderLine.ID);
            const config = this.contextsByLineID[lineID]?.config;
            const changes = this.changesByLineID[lineID];


            if (accentUtils.isEmpty(changes)) continue;



            applyActions.push({
                config: config,
                orderLine: orderLine,
                productName: this.getProductLabelForLineID(lineID),
                changes: changes,
                loadConfig: async (action) => {
                    if (accentUtils.isNull(action.config)) {
                        const actionConfig = await loadConfigModel({
                            ...this.configProps,
                            orderLine: action.orderLine,
                            orderLineOptions: JSON.parse(action.orderLine.OrderLineOptionsData),
                            onSave: e => saveOrderLineOptions(action.orderLine, e),
                            onNewOrderLineOption: this.onNewOrderLineOption
                        });

                        action.config = actionConfig;
                    }
                },
                applyChanges: async (action) => {
                    await applyMultiProductChanges(action.config, action.changes, this.props.getMultiConfig(), MultiConfigApplyChangesTag);

                    action.config.DoSave();
                }

            });



        }


        if (applyActions.length > 0) {

            await showDialog(<MultiConfigApplyOutstandingDlg actions={applyActions} />);

        }

    }


    async saveAll(){


        await this.waitWhileInProgress();

        await this.applyingChanges();

        await this.applyingChangesWithUnloadedConfigs();

        await this.waitWhileInProgress();

        const lineIDs = Object.keys(this.contextsByLineID);

        for (var i = 0; i < lineIDs.length; i++) {
            const ctx = this.contextsByLineID[lineIDs[i]];



            await ctx?.save();

        }


        this.orderLines.forEach(ln => {
            ln.Qty = this.props.primaryLine.Qty;
            ln.LocationOther = this.props.primaryLine.LocationOther;

        });

    }


    optionHasLinks(optionID, memberProductID){

        if (!accentUtils.isNull(this.optionLinkCache[optionID])) return this.optionLinkCache[optionID];

        const optionIDGuid = toGuid(optionID);

        const memberProduct = from(this.props.getMultiConfig()).firstOrDefault(p => compareValues(p.MemberID, memberProductID));

        const res = from(memberProduct.OptionLinks).any(l => compareValues(l.ProductOptionID, optionIDGuid));

        this.optionLinkCache[optionID] = res;

        return res;

    }


    onValueChanged(e, config){

        if (e.Tag === MultiConfigApplyChangesTag) return;


        const lineID = fromInt(config.OrderLine.ID);


        const sourceLine = this.getLineFromID(lineID);

        const optionModel = config.GetOptionModel(e.ID);


        if (!optionModel.Linked) return;

        const picklistCode = optionModel.GetProductOptionValue(optionModel.OrderLineOption.ProductOptionValueID)?.Code ?? null;



        this.orderLines.map(line => {

            const lID = line.ID;

            if (Number(lID) === Number(lineID)) return;

            const changes = this.changesByLineID[lID] ?? [];


            const change = {
                ...e,
                isSize: false,
                change: {
                    id: e.ID,
                    currentValue: optionModel.CurrentValue,
                    code: picklistCode,
                    customValue: optionModel.OrderLineOption.CustomValue,
                    additionalValue: optionModel.OrderLineOption.AdditionalValue,
                    supplierID: optionModel.OrderLineOption.SupplierID,
                    userSet: optionModel.OrderLineOption.UserSet,
                    memberProductID: config.Data.ID,
                    sourceConfigID: toGuid(sourceLine.MultiConfigID)
                }
            };



            changes.push({ ...change });

            this.changesByLineID[lID] = changes;

        });

    }

    onSizeChanged(e, config){


        if (e.Tag === MultiConfigApplyChangesTag) return;

        const lineID = config.OrderLine.ID;





        this.orderLines.map(line => {

            const lID = line.ID;

            if (Number(lID) === Number(lineID)) return;

            if (!config.OrderLine.WidthLinkedToSet && !config.OrderLine.HeightLinkedToSet) return;


            if (!this.canReceiveSizeFrom(lineID, lID)) return;


            const change = {
                ...e,
                isSize: true,
                change: {
                    width: config.OrderLine.WidthLinkedToSet ? { newWidth: config.OrderLine.Width, newWidthMeasurements: config.OrderLine.WidthMeasurements } : null,
                    height: config.OrderLine.HeightLinkedToSet ? { newHeight: config.OrderLine.Height, newHeightMeasurements: config.OrderLine.HeightMeasurements } : null
                }
            };

            const changes = this.changesByLineID[lID] ?? [];

            changes.push({ ...change });

            this.changesByLineID[lID] = changes;

        });



    }

    registerConfig(e){

        const configContext = e;

        const config = configContext.config;


        if (accentUtils.isNull(config)) return;

        this.contextsByLineID[config.OrderLine.ID] = configContext;


        const registerKey = `MultiConfiguratorContextProvider_${config.OrderLine.ID}`;

        if (!accentUtils.isEmpty(this.registrationKeys[registerKey])) {

            this.registrationKeys[registerKey].config = config;

            return;
        };

        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, e => this.onSizeChanged(e, config), registerKey);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, e => this.onValueChanged(e, config), registerKey);

        this.registrationKeys[registerKey] = { registerKey: registerKey, config: config };


    }

    unRegisterConfig(lineID) {

        
        this.contextsByLineID[lineID] = null;

        const registerKey = `MultiConfiguratorContextProvider_${lineID}`;

        const item = this.registrationKeys[registerKey];

        if (item?.config) {

            item.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, registerKey);
            item.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, registerKey);
        }

        this.registrationKeys[registerKey] = null;

    }



    getChanges(lineID){
        return this.changesByLineID[lineID] ?? [];
    }


    async save(){
        await this.saveAll();
    }


    async defaultNewLine(line, lineConfig) {

        const sourceSizeLine = from(this.orderLines).where(l => this.canReceiveSizeFrom(l.ID, line.ID)).select(l => ({ line: l, config: this.contextsByLineID[l.ID]?.config })).orderBy(x => accentUtils.isNull(x.config)).firstOrDefault();

        if (sourceSizeLine) {

            if (sourceSizeLine.config) {

                lineConfig.OrderLine.Width = sourceSizeLine.config.OrderLine.Width;
                lineConfig.OrderLine.Height = sourceSizeLine.config.OrderLine.Height;
                lineConfig.OrderLine.WidthMeasurements = sourceSizeLine.config.OrderLine.WidthMeasurements;
                lineConfig.OrderLine.DropMeasurements = sourceSizeLine.config.OrderLine.HeightMeasurements;


            } else {

                lineConfig.OrderLine.Width = toInt(sourceSizeLine.line.Width);
                lineConfig.OrderLine.Height = toInt(sourceSizeLine.line.Drop);
                lineConfig.OrderLine.WidthMeasurements = sourceSizeLine.line.WidthMeasurements;
                lineConfig.OrderLine.DropMeasurements = sourceSizeLine.line.HeightMeasurements;

            }

        }

        line.Qty = this.props.primaryLine.Qty;
        line.LocationOther = this.props.primaryLine.LocationOther;
        line.RequireInstall = this.props.primaryLine.RequireInstall;
        line.RequireDelivery = this.props.primaryLine.RequireDelivery;
        line.RequirePickup = this.props.primaryLine.RequirePickup;
        line.RequireCheckMeasure = this.props.primaryLine.RequireCheckMeasure;



        const otherConfigs = Object.keys(this.contextsByLineID).filter(k => k !== line.ID && !accentUtils.isNull(this.contextsByLineID[k]?.config)).map(k => this.contextsByLineID[k].config);


        const memberProduct = from(this.props.getMultiConfig()).where(p => compareValues(p.ID, toGuid(line.MultiConfigID))).firstOrDefault();
        



        lineConfig.DefaultNewMultiConfigLineValues(memberProduct, otherConfigs);


        lineConfig.DoSave();
    }

    


    includeLine(line) {

        line.__configDefaultRequired = true;

        if (this.props.includeLine) {
            this.props.includeLine(line);
        }
    }

    excludeLine(line) {
        if (this.props.excludeLine) {
            this.props.excludeLine(line);
        }
    }


    cleanUp() {
        Object.keys(this.registrationKeys).map((registerKey) => {

            const item = this.registrationKeys[registerKey];

            item.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, item.registerKey);
            item.config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, item.registerKey);
        });
    }

    notifyQtyChanged(lineID){

        const line = this.getLineFromID(lineID);

        this.orderLines.map(ol => {
            ol.Qty = line.Qty;
            if (ol.ID !== lineID) {
                ol._qtyChangeKey = (ol._qtyChangeKey ?? 0) + 1;
            }
        });

    }

    notifyLocationChanged(lineID){

        const line = this.getLineFromID(lineID);

        this.orderLines.map(ol => {
            ol.LocationOther = line.LocationOther;
            if (ol.ID !== lineID) {
                ol._locationChangeKey = (ol._locationChangeKey ?? 0) + 1;
            }
        });



    }

    notifyRequireCMChanged(lineID) {

        const line = this.getLineFromID(lineID);

        this.orderLines.map(ol => {
            ol.RequireCheckMeasure = line.RequireCheckMeasure;
            if (ol.ID !== lineID) {
                ol._requireCheckMeasureChangeKey = (ol._requireCheckMeasureChangeKey ?? 0) + 1;
            }
        });

    }

    notifyDispatchTypeChanged(lineID) {

        const line = this.getLineFromID(lineID);

        this.orderLines.map(ol => {
            ol.LocationOther = line.LocationOther;

            ol.RequireInstall = line.RequireInstall;
            ol.RequireDelivery = line.RequireDelivery;
            ol.RequirePickup = line.RequirePickup;

            if (ol.ID !== lineID) {
                ol._dispatchTypeChangeKey = (ol._dispatchTypeChangeKey ?? 0) + 1;
            }
        });


        if (this.props.onRequireRefresh) {
            this.props.onRequireRefresh();
        }
    }

    


}


export const ConfiguratorContextProvider = React.memo(props => {

    const [config, setConfig] = useState(null);    

    const [productNotFound, setProductNotFound] = useState(false);

    const contextRef = React.useRef(null);


    const isMarketplaceProduct = React.useMemo(() => {

        const prod = getProduct(props.productID, props.ProductVersionID);

        if (prod) {
            return prod.IsMarketplaceProduct;
        }

        return true;

    }, [props.productID, props.ProductVersionID])

    const multiCtx = props.multiConfigManager;

    const onNewOrderLineOption = multiCtx?.onNewOrderLineOption;

    const isInProgress = useConfigInProgress(`ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`, config, props.onInProgressChanged);

    const lineID = props.orderLine.ID;

    const isReadOnly = props.isReadOnly;

    const calculateProductSource = React.useCallback((e) => {


        if (config?.Data?.ProductSourceScript === null) return;

        if (!props.onProductSourceChanged) return;

        if (!props.allowProductSourceScript) return;

        var args = window.InsyteConfig.ProductSourceDefaultArgs.FromRTL(config); 
            
        var result = config.Data.ProductSourceScript.Calculate(args);



        if (!compareValues(result.SupplierID, config.OrderLine.SupplierID) &&  !compareValues(result.SupplierID, window.System.Guid.empty)) {
            const changed = props.onProductSourceChanged({
                SupplierID: result.SupplierID.toString(),
                AllowOverride: result.AllowOverride,
                Source: 1
            });

            if (changed) {
                config.OrderLine.SupplierID = result.SupplierID;
            }

        }


    }, [config, props]);


    const onValueChanged = React.useCallback(e => {

        if (props.onValueChanged) props.onValueChanged({ ...e, productID: props.productID, orderLineID: props.orderLine.ID });
        if (props.notifyDirty) props.notifyDirty();

        calculateProductSource();

    }, [props, calculateProductSource]);

    const onSizeChanged = React.useCallback(e => {


        if (props.onSizeChanged) props.onSizeChanged({ ...e, productID: props.productID, orderLineID: props.orderLine.ID });
        if (props.notifyDirty) props.notifyDirty();

    }, [props]);

    const waitWhileInProgress = React.useCallback(async () => {
        await waitForCondition(() => (!(isInProgress.current)));
        return isInProgress.current;
    }, [isInProgress]);


    const save = React.useCallback(async () => {
        await waitWhileInProgress();

        if (props.isReadOnly) return;
    
        config.DoSave();
    }, [config, waitWhileInProgress, props.isReadOnly]);



    React.useImperativeHandle(
        props.methods,
        () => ({
            save: async () => {

                await save();
              
            },
            waitWhileInProgress: async () => {
                return await waitWhileInProgress();                
            },
            cleanUp: () => {
                multiCtx?.unRegisterConfig(lineID);
            },
            notifyQtyChanged: () => {
                multiCtx?.notifyQtyChanged(lineID);
            },
            notifyLocationChanged: () => {
                multiCtx?.notifyLocationChanged(lineID);
            },
            notifyDispatchTypeChanged: () => {
                multiCtx?.notifyDispatchTypeChanged(lineID);
            },
            notifyRequireCMChanged: () => {
                multiCtx?.notifyRequireCMChanged(lineID);
            },
        }),
        [waitWhileInProgress, multiCtx, lineID, save]
    );


    React.useEffect(() => {

        loadConfigModel({ ...props, onNewOrderLineOption: onNewOrderLineOption, onChange : props.notifyDirty }).then(async c => {

            if (accentUtils.isNull(c)) {
                setProductNotFound(true);
            }


            if (props.orderLine.__configDefaultRequired) {

                if (props.multiConfigManager) {

                    await props.multiConfigManager.defaultNewLine(props.orderLine, c);


                }

            }


            setConfig(c);           
            });

    }, [setConfig, props.orderLine.ID, props.productID, onNewOrderLineOption]);

    React.useEffect(() => {

        if (accentUtils.isNull(config)) return;



        console.log("ConfiguratorContextProvider: (Mounted): ",props.orderLine.ID);

        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, onSizeChanged, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`);
        config.Subscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, onValueChanged, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`);

        if (props.onLoaded) props.onLoaded(config);

        return () => {

            if (accentUtils.isNull(config)) return;

            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.SizeChangeOccurred, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`)
            config.UnSubscribe(window.InsyteConfig.InsyteConfigEvents.ChangeOccurred, `ConfiguratorContextProvider_${props.productID}_${props.orderLine.ID}`)
        };


    }, [config, props.productID, props.orderLine.ID]);


    if (productNotFound) return <div>Product not found</div>;

    if (accentUtils.isNull(config)) return (props.loader) ? props.loader() :  <AccentSpinner />;


    contextRef.current = {
        config: config,
        isReadOnly: isReadOnly,
        orderLine: props.orderLine,
        productID: props.productID,
        filter: props.filter,
        isInProgress: isInProgress.current,
        notifyDirty: props.notifyDirty,
        baseImagePath: isMarketplaceProduct ? window.imageBaseURL : window.productImageBaseURL,
        waitWhileInProgress: waitWhileInProgress,
        save: save,
        getMultiConfigManager: () => multiCtx
    }


    if (!accentUtils.isNull(multiCtx)) {
        multiCtx.registerConfig(contextRef.current);
    }

    return <ConfiguratorContext.Provider value={contextRef.current}>
        {props.children}
    </ConfiguratorContext.Provider>


});
