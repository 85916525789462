// AccentLengthMetricUnbound.js
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { debounce } from 'perfect-debounce';
import { accentUtils, t } from '../../services/HelperService'; // Adjust the import path as necessary
import { AccentLabel } from '../AccentLabel';
import { fromLength, toLength, isMeasuredValue } from '../products/ProductConfiguratorShared';

export const AccentLengthMetricUnbound = ({
    defaultValue,
    defaultUnit,
    onChange,
    onBlur,
    onFocus,
    min,
    max,
    debounceMilliseconds,
    className,
    label,
    required,
    invalid,
    disabled,
    placeHolder,
    step,
    dataTagName
}) => {
    const [value, setValue] = useState(Number(defaultValue ? defaultValue : 0));
    const [isInvalid, setIsInvalid] = useState(false);
    const notifyChange = useRef(debounce((newValue) => {
        if (onChange) {
            onChange(toLength(newValue, defaultUnit));
        }
    }, debounceMilliseconds || 500)).current;

    useEffect(() => {
        // Cleanup debounce on unmount
        return () => {
            notifyChange.cancel && notifyChange.cancel();
        };
    }, [notifyChange]);

    /**
     * Checks if the value adheres to the specified precision.
     * @param {number} val - The value to check.
     * @param {number} stepVal - The precision step.
     * @returns {boolean} - True if valid, else false.
     */
    const isValidPrecision = (val, stepVal) => {
        if (stepVal === 0) return true; // Avoid division by zero
        const remainder = val % stepVal;
        // Handle floating point precision issues
        return Math.abs(remainder) < 1e-6 || Math.abs(remainder - stepVal) < 1e-6;
    };

    const handleChange = (e) => {
        let newVal = e.value;

        // Validate min and max
        if (!accentUtils.isNull(min) && newVal < min) {
            newVal = min;
        }
        if (!accentUtils.isNull(max) && newVal > max) {
            newVal = max;
        }

        // Enforce precision
        const stepVal = step || 1; // Default to whole numbers if step not provided
        if (isValidPrecision(newVal, stepVal)) {
            setIsInvalid(false);
            setValue(newVal);
            notifyChange(newVal);
        } else {
            // Option 1: Adjust to nearest valid step
            const adjustedVal = Math.round(newVal / stepVal) * stepVal;
            setIsInvalid(true); // Mark as invalid to provide visual feedback
            setValue(adjustedVal);
            notifyChange(adjustedVal);
        }
    };

    const handleBlur = () => {
        if (onBlur) {
           onBlur(toLength(value, defaultUnit));
        }
    };

    const handleFocus = (e) => {

        e.target.element.select();

        if (onFocus) {
           onFocus(toLength(value, defaultUnit));
        }
    };

    const isNotValid = required && (accentUtils.isNull(value) || isInvalid || !!invalid);
    const inputClassName = isNotValid ? `accentNumberUnbound invalid ${className}` : `accentNumberUnbound ${className}`;

    // Determine unit label based on defaultUnit
    const getUnitLabel = () => {
        const unitLabels = {
            [10]: 'mm',
            [100]: 'cm',
            [10000]: 'm'
        };
        return unitLabels[defaultUnit] || '';
    };

    // Determine decimal format based on precision
    const getDecimalFormat = () => {
        if (step === 0.5) return '#.0';
        if (step === 0.25) return '#.00';
        if (step === 0.1) return '#.0';
        if (step === 1) return '#';
        // Add more formats as needed based on precision
        return '#.00';
    };

    const format = `${getDecimalFormat()} ${getUnitLabel()}`;

    const control = (
        <NumericTextBox
            value={value}
            defaultValue={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={disabled}
            min={accentUtils.isNull(min) ? undefined : min}
            max={accentUtils.isNull(max) ? undefined : max}
            step={step || 0.1}
            className={inputClassName}
            style={{ width: '100%' }}
            format={format}
            placeholder={placeHolder}
            data-tagname={dataTagName}
        />
    );

    return label ? (
        <AccentLabel text={label}>
            <div className="d-flex" style={{ width: '100%', maxWidth: "120px" }}>
                {control}
            </div>
        </AccentLabel>
    ) : (
            <div className="d-flex" style={{ width: '100%', maxWidth: "120px" }} data-tagname={dataTagName}>
                {control}
            </div>
    );
};

AccentLengthMetricUnbound.propTypes = {
    defaultUnit: PropTypes.number.isRequired,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    debounceMilliseconds: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    placeHolder: PropTypes.string,
    step: PropTypes.number,
    dataTagName: PropTypes.string
};
