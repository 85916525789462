import { accentUtils , def, goBack, goTo, measurementUnits, newGuid, t} from './HelperService'
import * as DataService from './DataService'
import { getUser, getAccess } from './UserService'
import * as TemplateService from './TemplateService'
import * as JobService from './JobService'
import * as AppSettingsService from './AppSettingsService'

const query_BusinessUnit_Default = DataService.queryNoTracking("BusinessUnit_Default");

export const DefaultValueSetter = {

    DefaultSetting: function (entity ) {
        return Promise.resolve(entity);
    },
    BusinessUnit: function (entity) {

        return new Promise(p => {

            

            Promise.all([DataService.addEntity("Address"), DataService.addEntity("Address"), DataService.addEntity("Address")]).then(([newAddress, newDeliveryAddress, newBillingAddress]) => {

                const user = getUser();

                entity.Logo = user.DefaultSettings.BusinessUnit.Logo;
                entity.Phone = user.DefaultSettings.BusinessUnit.Phone;
                entity.Mobile = user.DefaultSettings.BusinessUnit.Mobile;
                entity.BusinessNumber = user.DefaultSettings.BusinessUnit.BusinessNumber;
                entity.Locale = user.DefaultSettings.BusinessUnit.Locale;
                entity.TimeZone = user.DefaultSettings.BusinessUnit.TimeZone;
                entity.Email = user.DefaultSettings.BusinessUnit.Email;
                entity.WWW = user.DefaultSettings.BusinessUnit.WWW;



                newAddress.AddressLines = user.DefaultSettings.BusinessUnit.Address.AddressLines;
                newAddress.City = user.DefaultSettings.BusinessUnit.Address.City;
                newAddress.State = user.DefaultSettings.BusinessUnit.Address.State;
                newAddress.Postcode = user.DefaultSettings.BusinessUnit.Address.Postcode;
                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;


                newDeliveryAddress.AddressLines = user.DefaultSettings.BusinessUnit.DeliveryAddress.AddressLines;
                newDeliveryAddress.City = user.DefaultSettings.BusinessUnit.DeliveryAddress.City;
                newDeliveryAddress.State = user.DefaultSettings.BusinessUnit.DeliveryAddress.State;
                newDeliveryAddress.Postcode = user.DefaultSettings.BusinessUnit.DeliveryAddress.Postcode;
                newDeliveryAddress.Country = user.DefaultSettings.BusinessUnit.DeliveryAddress.Country;

                newBillingAddress.AddressLines = user.DefaultSettings.BusinessUnit.BillingAddress.AddressLines;
                newBillingAddress.City = user.DefaultSettings.BusinessUnit.BillingAddress.City;
                newBillingAddress.State = user.DefaultSettings.BusinessUnit.BillingAddress.State;
                newBillingAddress.Postcode = user.DefaultSettings.BusinessUnit.BillingAddress.Postcode;
                newBillingAddress.Country = user.DefaultSettings.BusinessUnit.BillingAddress.Country;


                entity.Address = newAddress;
                entity.DeliveryAddress = newDeliveryAddress;
                entity.BillingAddress = newBillingAddress;
                

                p(entity);

            });
        });
    },
    DocumentTemplate: function (entity) {

        if (entity.Type === TemplateService.documentTemplateTypes.quote) {
            entity.TemplateID = TemplateService.documentTemplateIDs.quote01;
        } else if (entity.Type === TemplateService.documentTemplateTypes.orderConfirmation) {
            entity.TemplateID = TemplateService.documentTemplateIDs.orderConfirmation01;
        } else if (entity.Type === TemplateService.documentTemplateTypes.invoice) {
            entity.TemplateID = TemplateService.documentTemplateIDs.invoice01;
        } else if (entity.Type === TemplateService.documentTemplateTypes.statement) {
            entity.TemplateID = TemplateService.documentTemplateIDs.statement01;
        } else if (entity.Type === TemplateService.documentTemplateTypes.installer) {
            entity.TemplateID = TemplateService.documentTemplateIDs.installerPrint01;
        } else if (entity.Type === TemplateService.documentTemplateTypes.jobSummary) {
            entity.TemplateID = TemplateService.documentTemplateIDs.jobSummary01;
        }


        return Promise.resolve(entity);
    },
    Representative: function (entity) {
        entity.Active = true;
        return new Promise(p => {
            DataService.addEntity("Address").then(newAddress => {

                const user = getUser();

                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;
                entity.Address = newAddress;

                p(entity);

            })
        });
    },
    Activity: function (entity) {

        return new Promise(p => {
            DataService.addEntity("Address").then(newAddress => {

                const user = getUser();

                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;
                entity.Address = newAddress;

                p(entity);

            })
        });
    },
    TaxRate: function (entity) {
        return Promise.resolve(entity);
    },
    CommissionRun: function (entity) {

        if (accentUtils.isNull(entity.StartDate) || new Date(entity.StartDate).getFullYear() === 1900) {
            entity.StartDate = null;
            entity.EndDate = null;
        }



        return Promise.resolve(entity);
    },
    Area: function (entity) {

        entity.Active = true;

        return Promise.resolve(entity);
    },
    Fabric: function (entity) {

        entity.MeasureUnit = measurementUnits.millimeter;
        entity.AreaUnit = 100000000;

        return Promise.resolve(entity);
    },
    OrderLine: function (entity, parent) {


        const supplierID = accentUtils.isNull(parent) ? null : parent.SupplierID

        if (!accentUtils.isNull(parent)) {
            entity.OrderID = parent.ID;
        }

        entity.Width = 0;
        entity.Drop = 0;
        entity.Qty = 1;
        entity.Price = 0;
        entity.PriceTax = 0;
        entity.PriceIncTax = 0;
        entity.SalePrice = 0;
        entity.SalePriceTax = 0;
        entity.SalePriceIncTax = 0;
        entity.OriginalCostPrice = 0;
        entity.OriginalCostPriceTax = 0;
        entity.OriginalCostPriceIncTax = 0;
        entity.CostPrice = 0;
        entity.CostPriceTax = 0;
        entity.CostPriceIncTax = 0;
        entity.Status = "status_po_line_open";
        entity.OrderLineID = newGuid();

        entity.UserSetAsIncTax = AppSettingsService.Settings.GetAppSettings()?.UserSetAsIncTax ?? false;

        if (!accentUtils.isNull(supplierID))
            entity.SupplierID = supplierID;

        return Promise.resolve(entity);

    },
    JobLine: function (entity, parent, custom) {

        if (!accentUtils.isNull(parent)) {
            entity.JobID = parent.ID;
        }

        entity.Width = 0;
        entity.Drop = 0;
        entity.Qty = 1;
        entity.Price = 0;
        entity.PriceTax = 0;
        entity.PriceIncTax = 0;
        entity.SalePrice = 0;
        entity.SalePriceTax = 0;
        entity.SalePriceIncTax = 0;
        entity.OriginalCostPrice = 0;
        entity.OriginalCostPriceTax = 0;
        entity.OriginalCostPriceIncTax = 0;
        entity.CostPrice = 0;
        entity.CostPriceTax = 0;
        entity.CostPriceIncTax = 0;
        entity.UserSetAsIncTax = AppSettingsService.Settings.GetAppSettings()?.UserSetAsIncTax ?? false;
        entity.Status = JobService.jobLineStatus.Open;

        if (parent.Stage === "stage_job_order_edit") {

            if (JobService.Helper.jobIsInCheckMeasure(parent)) {

                if (JobService.Helper.jobIsInCheckMeasureAproval(parent)) {
                    entity.Stage = JobService.jobLineStages.CMCompleted_Confirmed;
                } else {
                    entity.Stage = JobService.jobLineStages.Confirmed_CM;
                }

            } else {

                entity.Stage = JobService.jobLineStages.Confirmed;
                entity.Status = JobService.jobLineStatus.Edit;
            }



            entity.OrderDate = new Date();

        } else {
            entity.Stage = "stage_job_line_unconfirmed";
        }

        entity.LineType = JobService.jobLineTypes.New;

        var defaultDispatchType = def(AppSettingsService.Settings.GetAppSettings().DefaultDispatchType, "INSTALL");

        
        if (defaultDispatchType === "DELIVERY") {

            entity.RequirePickup = false;
            entity.RequireInstall = false;
            entity.RequireDelivery = true;

        } else if (defaultDispatchType === "PICKUP") {

            entity.RequirePickup = true;
            entity.RequireInstall = false;
            entity.RequireDelivery = false;

        } else {

            entity.RequirePickup = false;
            entity.RequireInstall = true;
            entity.RequireDelivery = false;
        }



        entity.JobLineID = newGuid();
        var lineIsBeingCopied = custom && (custom.isCopy || custom.isMultiLine);


        if (parent.JobType == "type_job_service") {

            entity.LineType = JobService.jobLineTypes.Service;

            return new Promise(p => {


                if (lineIsBeingCopied) {
                    p(entity);
                } else {

                    JobService.Helper.showNewServiceLineWizard(entity).then(r => {

                        if (r.canceled) {
                            DataService.clearEntities();
                            goBack();
                            return;
                        }

                        var requireServiceRec = !accentUtils.isNull(r) && !accentUtils.isNull(r.newLine) && (r.newLine.LineType == JobService.jobLineTypes.Alteration || r.newLine.LineType == JobService.jobLineTypes.Remake);


                        if (requireServiceRec) {
                            DataService.addEntity("JobLineService").then(s => {

                                s.JobID = parent.ParentID;
                                s.JobLineID = r.originalLineID;

                                r.newLine.JobLineService = s;

                                p(r.newLine);


                            });
                        } else {
                            p(accentUtils.isNull(r) ? null : r.newLine);
                        }

                    });

                }
            });

        } else {
            return Promise.resolve(entity);
        }



    },
    Invoice: function (entity) {


        return new Promise(p => {

            DataService.addEntity("Address").then(newAddress => {

                const user = getUser();

                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;
                entity.BillingAddress = newAddress;
                entity.RepresentativeID = user.ID;
                entity.InvoiceDate = new Date();

                entity.Status = "status_invoice_open";

                entity.BusinessUnitID = user.DefaultSettings.BusinessUnit.ID;

                p(entity);
            });

        });

    },
    InvoiceLine: function (entity, parent) {

        entity.InvoiceLineID = newGuid();
        entity.Status = "status_invoice_line_open";


        return Promise.resolve(entity);

    },
    OrderLineOption: function (entity) {

        return Promise.resolve(entity);
    },
    PriceMarkup: function (entity) {

        return Promise.resolve(entity);
    },

    OrderLineLocation: function (entity) {

        return Promise.resolve(entity);
    },

    JobCustomStatus: function (entity) {
        return Promise.resolve(entity);
    },
    Address: function (entity) {

        const user = getUser();

        entity.Country = user.DefaultSettings.Country;
        return Promise.resolve(entity);
    },
    Opportunity: function (entity) {

        return new Promise(p => {

            DataService.addEntity("Address").then(newAddress => {

                const user = getUser();

                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;

                entity.AssignedDate = new Date();
                entity.RepresentativeID = accentUtils.isEmpty(user.DefaultSettings.AutoAllocateLeadsToUserID) ? user.ID : user.DefaultSettings.AutoAllocateLeadsToUserID;
                entity.LeadGeneratorID = user.ID;
                entity.Code = 'None';
                entity.LeadDate = new Date();
                entity.AccountType = JobService.accountTypes.retail;
                entity.Status = "status_lead_open";
                entity.PipelineStage = "stage_lead_lead";
                entity.Forecast = true;
                entity.Address = newAddress;
                

                p(entity);

            });


        });
    },
    PurchaseOrder: function (entity) {

        return new Promise(p => {

            Promise.all([DataService.addEntity("Address"), DataService.addEntity("Address")]).then(([newBillingAddress, newDeliveryAddress]) => {

                const user = getUser();

                newDeliveryAddress.AddressLines = user.DefaultSettings.BusinessUnit.DeliveryAddress.AddressLines;
                newDeliveryAddress.City = user.DefaultSettings.BusinessUnit.DeliveryAddress.City;
                newDeliveryAddress.State = user.DefaultSettings.BusinessUnit.DeliveryAddress.State;
                newDeliveryAddress.Postcode = user.DefaultSettings.BusinessUnit.DeliveryAddress.Postcode;
                newDeliveryAddress.Country = user.DefaultSettings.BusinessUnit.DeliveryAddress.Country;


                newBillingAddress.AddressLines = user.DefaultSettings.BusinessUnit.BillingAddress.AddressLines;
                newBillingAddress.City = user.DefaultSettings.BusinessUnit.BillingAddress.City;
                newBillingAddress.State = user.DefaultSettings.BusinessUnit.BillingAddress.State;
                newBillingAddress.Postcode = user.DefaultSettings.BusinessUnit.BillingAddress.Postcode;
                newBillingAddress.Country = user.DefaultSettings.BusinessUnit.BillingAddress.Country;



                entity.PurchaseOrderID = newGuid();

                entity.RepresentativeID = user.ID;
                entity.OrderNumber = 'None';
                entity.OrderDate = new Date();
                entity.Status = "status_po_open";
                entity.StatusDate = new Date();

                entity.BillingAddress = newBillingAddress;
                entity.DeliveryAddress = newDeliveryAddress;



                p(entity);
            });
        });

    },
    Payment: function (entity) {
        entity.Date = new Date();

        if (accentUtils.isEmpty(entity.Type)) {
            entity.Type = JobService.PaymentTypes.Payment;
        }
        entity.Status = "status_payment_draft";

        return Promise.resolve(entity);
    },
    DiscountItem: function (entity) {
        entity.Start = new Date();
        entity.Active = true;

        return Promise.resolve(entity);
    },
    Job: function (entity) {

        return new Promise(p => {

            const user = getUser();


            Promise.all([
                DataService.addEntity("Tracking"),
                DataService.addEntity("Address"),
                DataService.addEntity("Address"),
                (user.DefaultSettings.BusinessUnit.ID == entity.BusinessUnitID || accentUtils.isEmpty(entity.BusinessUnitID)) ? Promise.resolve(user.DefaultSettings.BusinessUnit) : query_BusinessUnit_Default.getFirstOrDefault({ id: entity.BusinessUnitID })

            ]).then(([newTracking, newAddress, newDeliveryAddress, businessUnit]) => {
                newTracking.TrackingData = "{}";



                newDeliveryAddress.AddressLines = businessUnit.DeliveryAddress.AddressLines;
                newDeliveryAddress.City = businessUnit.DeliveryAddress.City;
                newDeliveryAddress.State = businessUnit.DeliveryAddress.State;
                newDeliveryAddress.Postcode = businessUnit.DeliveryAddress.Postcode;
                newDeliveryAddress.Country = businessUnit.DeliveryAddress.Country;

                newAddress.Country = user.DefaultSettings.BusinessUnit.Address.Country;


                entity.Tracking = newTracking;
                entity.SalesRepID = user.ID;
                entity.Reference = 'None';
                entity.JobDate = new Date();
                entity.Status = "status_job_open";
                entity.Stage = "stage_job_quote";
                entity.JobID = newGuid();
                entity.ReceiveAutomatedEmail = true;
                entity.ReceiveAutomatedSMS = true;
                if (accentUtils.isEmpty(entity.JobType)) {
                    entity.JobType = "type_job_sales";
                }
                entity.Priority = "priority_job_standard";
                entity.StatusDate = new Date();
                
                entity.PL_CommitmentReasonID = user.DefaultSettings.DefaultCommitmentReasonID;
                var expDate = new Date();
                expDate.setDate(expDate.getDate() + user.DefaultSettings.QuoteExpiryDays);
                entity.QuoteExpiryDate = expDate;
                entity.PaymentTerms = user.DefaultSettings.DefaultPaymentTerms;
                entity.InvoiceDueDays = user.DefaultSettings.InvoiceDueDays;
                entity.DefaultPaymentDue = user.DefaultSettings.DefaultPaymentDue;

                entity.Address= newAddress;
                entity.DeliveryAddress = newDeliveryAddress;

                p(entity);
            });

        });

    },
    Contact: function (entity) {

        return new Promise(p => {
            DataService.addEntity("Address").then(newAddress => {
                entity.Address = newAddress;
                entity.ReceiveAutomatedEmail = true;
                entity.ReceiveAutomatedSMS = true;
                p(entity);

            });

        });

    },
    Company: function (entity) {


        return new Promise(p => {

            DataService.addEntity("Address").then(newAddress => {

                const user = getUser();

                entity.DefaultPaymentTerms = user.DefaultSettings.DefaultPaymentTerms;
                entity.InvoiceDueDays = user.DefaultSettings.InvoiceDueDays;
                entity.DefaultPaymentDue = user.DefaultSettings.DefaultPaymentDue;
                entity.AccountType = JobService.accountTypes.retail;
                entity.Address = newAddress;

                p(entity);

            });

        });

    },
    Template: function (entity) {

        return Promise.resolve(entity);

    },


};

window.DefaultValueSetter = DefaultValueSetter;
