import React from 'react'
import { accentUtils, t, formHelper, newGuid, goTo } from '../../services/HelperService'
import { getEntityMemberValue, setEntityMemberValue } from '../../services/DataService'
import { AccentLabel } from '../AccentLabel'
import { AccentDate } from './AccentDate'
import { AccentNumber } from './AccentNumber'
import { AccentImage } from './AccentImage'
import { AccentHTML } from './AccentHTML'
import { AccentDecimal } from './AccentDecimal'
import { AccentCheck } from './AccentCheck'
import { AccentTextArea } from './AccentTextArea'
import { AccentComboBox } from './AccentComboBox'
import { AccentTextLookup } from './AccentTextLookup'
import { AccentMultiSelect } from './AccentMultiSelect'
import { AccentPassword } from './AccentPassword'
import { AccentPhoneNumber } from './AccentPhoneNumber'
import { AccentEmail } from './AccentEmail'
import { AccentText } from './AccentText'
import { AccentTooltip } from './../AccentTooltip'
import { withViewState } from '../../app-framework/ViewState'
import { debounce } from 'perfect-debounce'
import { AccentLength } from './AccentLength'

class FormCtrl extends React.Component {

    constructor(props) {
        super(props);

        this.controlID = `cid_${newGuid()}`;


        this.ctrl = React.createRef();
        this.internalChange = false;
        this.ctrlRefreshKey = 1;

        this.clearValue = this.clearValue.bind(this);
        this.hasValidation = this.hasValidation.bind(this);
        this.setReadOnly = this.setReadOnly.bind(this);
        this.refresh = this.refresh.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.getValue = this.getValue.bind(this);
        this.validate = this.validate.bind(this);
        this.getState = this.getState.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.updateValidState = this.updateValidState.bind(this);
        this.getFormControl = this.getFormControl.bind(this);


        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.getLabel = this.getLabel.bind(this);

        this.state = this.getInitialState();
        this.onModelPropertyChanged = this.onModelPropertyChanged.bind(this);
        this.getModelValue = this.getModelValue.bind(this);
        this.setModelValue = this.setModelValue.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    componentDidMount() {
        if (this.props.focus) {
            if (this.ctrl.current.handleFocus) {
                this.ctrl.current.handleFocus();
            }
        }


        this.props.model?.entity?.entityAspect?.propertyChanged?.subscribe(this.onModelPropertyChanged);

    }

    componentWillUnmount() {
        this.props.model?.entity?.entityAspect?.propertyChanged?.unsubscribe(this.onModelPropertyChanged);
    }

    onLinkClick() {
        if (!accentUtils.isNull(this.props.model?.field?.link)) {

            goTo(`/${this.props.model.field.link.view}/${this.props.model.field.link.id}`)

        }
    }

    clearValue(){
        if (this.ctrl.current.clearValue){
            this.ctrl.current.clearValue();
        }
    }


    getFormControl(){
        return this.ctrl.current;
    }
    
    hasValidation() {
        return !accentUtils.isNull(this.props.model.field.validate) || (!accentUtils.isNull(this.ctrl.current) &&!accentUtils.isNull(this.ctrl.current.validateControl));  
    }
    
    setReadOnly(readonly){
        this.props.model.field.options.readonly = readonly;
        this.refresh();
    }
    
    refresh(){
        this.updateValidState();  
    }

    refreshData() {

        var me = this;
        return new Promise(function (p) {

            if (!accentUtils.isNull(me.ctrl.current)) {
                if (!accentUtils.isNull(me.ctrl.current.refreshData)) {
                    me.ctrl.current.refreshData().then(function () {
                        me.updateValidState();
                        p();
                    });
                }
            }
        });  
    }


    getLabel() {

        var label = "";


        if (!accentUtils.isNull(this.props.model.entity.entityType)) {

            var entityTypes = [this.props.model.entity.entityType.shortName];

            if (!accentUtils.isNull(this.props.model.entity.entityType.baseEntityType)) {
                entityTypes.push(this.props.model.entity.entityType.baseEntityType.shortName);
            }

            if (accentUtils.isNull(this.props.model.field.label))
                label = formHelper.getFieldLabel(t, entityTypes, this.props.model.field.fieldName, this.props.model.field.abbreviateLabel);
            else
                label = this.props.model.field.label;

        } else {
            label = this.props.model.field.label;
        }


        label = t(label);

        return label;
    }

    getValue() {

        if (!accentUtils.isNull(this.ctrl.current.getControlValue)) {
            return this.ctrl.current.getControlValue();
        }

    }

    validate(props) {

        
      var hasValidation = this.hasValidation();
      
      if (!hasValidation)
        return null;
         
      var existingValue = getEntityMemberValue(props.model.entity, props.model.field.fieldName);

      var msg = null;
      if (!accentUtils.isNull(props.model.field.validate)) {
          msg = props.model.field.validate(existingValue);
      }

      if (accentUtils.isNull(msg) && !accentUtils.isNull(this.ctrl.current) && !accentUtils.isNull(this.ctrl.current.validateControl)) {
          msg = this.ctrl.current.validateControl();
      }


      return msg;
       
        
    }
    
    
    getState(validStateOnly, props){
          
          
          if (accentUtils.isNull(validStateOnly)){
              validStateOnly = false;
          }
          if (accentUtils.isNull(props)){
              props = this.props;
          }
          
          var v = accentUtils.isNull(props.model) ? null : this.validate(props);
          var msg= "";
          var classname = "accentValidCtrl";
          var valid = true;
          if (v != null){
              if (v.Type == "Error"){
                  classname = "accentInvalidCtrl";
                  valid = false;             
              }else if (v.Type == "Warning"){
                  classname = "accentWarningCtrl";
                  valid = false;
              }
              
              msg = v.Msg;
              
          }
          return{
                validStateOnly :validStateOnly,
                isValid: valid,
                classNames :  classname,
                msg: msg
          };
          
    }
    
    getInitialState(){
        var state = this.getState();      
        return state;
    }
    
    updateValidState(){
        this.setState(this.getState(false));
    }


    onModelPropertyChanged(propertyChangedArgs) {

        const propertyNameChanged = propertyChangedArgs.propertyName;
        const isThisProps = propertyNameChanged === this.props.model.field.fieldName;

        if (isThisProps) {
            const tt = isThisProps;
        }


        if (this.internalChange || !isThisProps) return;

        var entity = propertyChangedArgs.entity;

        var oldValue = propertyChangedArgs.oldValue;
        var newValue = propertyChangedArgs.newValue;

        if (this.props.setViewState) {

            if (this.props.model.field.options.preventIsDirty) return;

            this.props.setViewState({ isDirty: true });
        }
        this.ctrlRefreshKey++;
        this.forceUpdate();

    }


    getModelValue() {
        return getEntityMemberValue(this.props.model.entity, this.props.model.field.fieldName);
    }

    setModelValue(value) {


        const me = this;

        var existingValue = getEntityMemberValue(me.props.model.entity, me.props.model.field.fieldName);
        if (existingValue != value) {


            try {

                me.internalChange = true;

                setEntityMemberValue(me.props.model.entity, me.props.model.field.fieldName, value);

                setTimeout(function () {
                    if (!accentUtils.isNull(me.props.onChange))
                        me.props.onChange(value);

                    me.updateValidState();


                }, 0);

            } finally {
                me.internalChange = false;
            }

        }

    }

    render() {

        var me = this;


        if (accentUtils.isNull(this.props.model)) {
            return null;
        }

        var type = this.props.model.field.type;
        
        var viewReadOnly = false;
       
        if (!accentUtils.isNull(this.props.viewState?.isReadOnly)){
            viewReadOnly = this.props.viewState.isReadOnly;
        }


        const label = me.getLabel();
        
        var model =  {
            refresh: me.refresh,
            required: false,
            validStateOnly: me.state.validStateOnly,
            modified:function(){
                if (me.props.setViewState) {

                    if (me.props.model.field.options.preventIsDirty) return;

                    me.props.setViewState({ isDirty : true})
                }
            },
            isModified: function(){
                return me.props.viewState?.isDirty;
            },
            isReadOnly: viewReadOnly,
            options: this.props.model.field.options,
            onChange: this.props.model.onChange,
            displayLabel: undefined,
            getValue: e=> me.getModelValue(e),
            setValue: e=> me.setModelValue(e)
             
        };

        var readOnly = formHelper.isControlReadOnly(model) || viewReadOnly;        
        var required = !readOnly && !this.state.isValid;
        model.required = required;

        var overrideReadonly = viewReadOnly && !formHelper.isControlReadOnly(model);
        
        var ctrl = [type].map(function(ctrlType){
            
            if (ctrlType === "date") {
                return (
                    <AccentDate ref={ me.ctrl} model={model} />
                );
            }else if (ctrlType === "datetime"){
                    return (
                        <AccentDate ref={me.ctrl} model={model} showTime={true} />
                );
            } else if (ctrlType === "time") {
                return (
                    <AccentDate ref={me.ctrl} model={model} showTime={true} showDate={false}/>
                );
            }else if (ctrlType === "number"){
                    return (
                        <AccentNumber ref={me.ctrl} model={model} />
                );
            } else if (ctrlType === "length") {
                return (
                    <AccentLength ref={me.ctrl} model={model} />
                );
            }else if (ctrlType === "image"){
                    return (
                        <AccentImage ref={me.ctrl} model={model} />
                );
            }else if (ctrlType === "html"){
                    return (
                        <AccentHTML ref={me.ctrl} model={model} />
                );
            }else if (ctrlType === "decimal"){
                return (
                        <AccentDecimal ref={me.ctrl} model={model} />
                );
            }else if (ctrlType === "check"){
                return (
                    <AccentCheck ref={me.ctrl} model={{ ...model, displayLabel: label }} style={{ paddingTop: "25px", ...me.props.style }} />
                );
            }else if (ctrlType === "textArea"){
                return (
                        <AccentTextArea ref={me.ctrl} model={model} />
                );
            } else if (ctrlType === "select") {
                
                return (
                    <AccentComboBox ref={me.ctrl} model={model}/>
                );
            }else if (ctrlType === "textLookup"){
                return (
                        <AccentTextLookup ref={me.ctrl} model={model} />
                );
            }else if (ctrlType === "multiSelect"){
                return (
                        <AccentMultiSelect ref={me.ctrl} model={model} />
                );
            }
            else if (ctrlType === "password") {
                return (
                    <AccentPassword ref={me.ctrl} model={model} />
                );
            } else if (ctrlType === "phone") {
                return (
                    <AccentPhoneNumber ref={me.ctrl} model={model} />
                );
            } else if (ctrlType === "mobile") {
                return (
                    <AccentPhoneNumber ref={me.ctrl} model={model} isMobile />
                );
            } else if (ctrlType === "email") {
                return (
                    <AccentEmail ref={me.ctrl} model={model} />
                );
            }else{
                return (
                    <AccentText ref={me.ctrl} model={model} />
                );
            }
        })[0];
        
        var state = this.getState();

        var orroclass = overrideReadonly ? " acc-ctr-orro" : "";

        
        const preControls = accentUtils.isEmpty(me.props.pre) ? null : me.props.pre.filter(p=> !accentUtils.isNull(p)).map((c, i) => <div key={i} className="acc-pre">{c}</div>)
        const postControls = accentUtils.isEmpty(me.props.post) ? null : me.props.post.filter(p => !accentUtils.isNull(p)).map((c, i) => <div key={i} className="acc-post">{c}</div>)
        let control = null;


        if (required){

            var tooltipID = "tooltip-" + this.controlID;
            
            var tooltip = <AccentTooltip text={state.msg} target={ tooltipID } />;
        
            control = <div className={state.classNames + orroclass + " flex-fill"} id={tooltipID} >{ctrl}{tooltip}</div>;           
        }else{
            control = <div className={"accentValidCtrl" + orroclass + " flex-fill"}>
                    {ctrl}
                </div>;
        }

        const result = <div className="d-flex" key={this.ctrlRefreshKey} data-tagname={this.props.dataTagName ?? this.props.model.field.fieldName} >            
                {preControls}            
                {control}
                {postControls}            
        </div>;



        if (me.props.noLabel) {
            return result;
        }

        if (type === "check") {
            return result;
        }


        

        const hasValue = !accentUtils.isEmpty(this.getModelValue());

        return <AccentLabel onClick={!accentUtils.isNull(this.props.model?.field?.link) && hasValue ? this.onLinkClick : undefined } text={label}>{result}</AccentLabel>;

    }
}


export const AccentFormCtrl = withViewState(FormCtrl);
export const AccentFormCtrlNoViewState = FormCtrl;


const onValidationChangedDebounced = debounce(action => action(), 500, { trailing: false });




export const withValidation = (Component, selector) => React.memo((props) => {

    const validation = useValidation(selector);


    return <Component {...props} validation={validation} />;

});

export function useValidation(selector) {

    
    const [, setRefresh] = React.useState(1);
    const validation = React.useRef(true);
    const observer = React.useRef(null);



    const onChange = React.useCallback(e => {
        const element = document.querySelector(selector);

        if (accentUtils.isNull(element)) return;

        const newValue = formHelper.isValid(selector);

        if (validation.current === newValue) return;

        validation.current = newValue;

        onValidationChangedDebounced(() => setRefresh(r => r + 1));

        

    }, [validation, setRefresh]);



    React.useEffect(() => {


        observer.current = new MutationObserver(e => onChange(e));

        observer.current.observe(document.body, {
            childList: true,
            attributes: true,
            characterData: false,
            subtree: true,
            attributeOldValue: false,
            characterDataOldValue: false
        });

        return () => {
            observer.current.disconnect();
        };

    }, []);

    return {
        isValid: validation.current
    }; 



}